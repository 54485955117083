import { Component, OnInit } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { AlertService } from 'src/app/shared/alert.service';
import { Router } from '@angular/router';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'kl-why-star-rating',
  templateUrl: './why-star-rating.component.html',
  styleUrls: ['./why-star-rating.component.scss']
})
export class WhyStarRatingComponent implements OnInit {
  public aboutPage: any;
  askPanels = [];
  coverPage: any;
  feedbackFields: any;
  yStarRating: any;
  jharkhand: any;
  pageload: boolean = false;
  feeback = {
    name: '',
    email: '',
    mobile: '',
    comment: ''
  };
  veryPoor = 1;
  poor = 2;
  moderate = 3;
  good = 4;
  veryGood = 5;
  pageLanguage: string;
  public keyAdvanteages:any=[
    {
      heading: "Transparency of information and access to pollution ratings",
      text: "The Star Rating Program makes information on polluters available for the public in a transparent and accessible manner"
    },
    {
      heading: "Regular interaction between industries and regulators",
      text: "Integrating the Star Rating program into the regulatory framework can facilitate interaction between the industries and regulators"
    },
    {
      heading: "Tracking of industrial pollution",
      text: "Regularly uploaded Star Rating Report cards can lead to effective tracking of emissions"
    },
    {
      heading: "Low-cost Implementations",
      text: "The program has low cost and high effectiveness, as most pollution control boards in the country already collects high quality data on industrial emissions generated through CEMS"
    },
    {
      heading: "Pollution abatement technology adaptation",
      text: "Information on industrial emissions can incentivize adopting technology to decrease air pollution"
    }
  ]
  constructor(
    public _http: HttpLayerService,
    public toaster: AlertService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getTranslations();
    let route = this.router.url.split('/');
    this.pageLanguage = route.pop();
    this.getContent();
    // var div = 360 / 5;
    // var radius = 150;
    // var parentdiv = document.getElementById('parentdiv');
    // var offsetToParentCenter = parseInt(JSON.stringify(parentdiv.offsetWidth / 2)); //assumes parent is square
    // var offsetToChildCenter = 50;
    // var totalOffset = offsetToParentCenter - offsetToChildCenter;
    // for (var i = 1; i <= 5; ++i) {
    //   var childdiv = document.createElement('div');
    //   childdiv.className = 'div2';
    //   childdiv.style.position = 'absolute';
    //   var y = Math.sin((div * i) * (Math.PI / 180)) * radius;
    //   var x = Math.cos((div * i) * (Math.PI / 180)) * radius;
    //   childdiv.style.top = (y + totalOffset).toString() + "px";
    //   childdiv.style.left = (x + totalOffset).toString() + "px";
    //   parentdiv.appendChild(childdiv);
    // }
  }
  getContent() {
    try {
      this._http.get(Config.JSON.GET_ABOUTPAGE_CONTENT).subscribe((result) => {
        if (result && result['aboutPage']) {
          this.aboutPage = result['aboutPage']
        } else {
          this.toaster.open('failed', 'Failed', 'Failed to Fetch Data')
        }
      })

    } catch (error) {
      console.log(error)
    }
  }
  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['aboutStarProgramComponent']) {
            this.askPanels = result['aboutStarProgramComponent']['askPanels'];
            this.coverPage = result['aboutStarProgramComponent']['coverPage'];
            this.feedbackFields = result['aboutStarProgramComponent']['feedback'];
            this.yStarRating = result['aboutStarProgramComponent']['yStarRating'];
            this.jharkhand = result['aboutStarProgramComponent']['jharkhand'];
            this.pageload = true;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  routeToIndustryRating(){
    this.router.navigate(['industry-rating']);
  }

}
