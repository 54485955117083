import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { PagesModule } from './pages/pages.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'star-rating-portal',
    pathMatch: 'full',
  },
  {
    path: 'pages',
    loadChildren:'./pages/pages.module#PagesModule',
  },
  {
    path: 'star-rating-portal',
    loadChildren: './star-rating-portal/star-rating-portal.module#StarRatingPortalModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule { }
