import { Component, OnInit } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { AlertService } from 'src/app/shared/alert.service';
import { Router } from '@angular/router';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'kl-why-jarkhand',
  templateUrl: './why-jarkhand.component.html',
  styleUrls: ['./why-jarkhand.component.scss']
})
export class WhyJarkhandComponent implements OnInit {
  askPanels = [];
  coverPage: any;
  feedbackFields: any;
  yStarRating: any;
  jharkhand: any;
  feeback = {
    name: '',
    email: '',
    mobile: '',
    comment: ''
  };
  pageLanguage: string;
  constructor(
    public _http: HttpLayerService,
    public toaster: AlertService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getTranslations();
    let route = this.router.url.split('/');
    this.pageLanguage = route.pop();
  }

  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['aboutStarProgramComponent']) {
            this.askPanels = result['aboutStarProgramComponent']['askPanels'];
            this.coverPage = result['aboutStarProgramComponent']['coverPage'];
            this.feedbackFields = result['aboutStarProgramComponent']['feedback'];
            this.yStarRating = result['aboutStarProgramComponent']['yStarRating'];
            this.jharkhand = result['aboutStarProgramComponent']['jharkhand'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
}
