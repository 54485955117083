import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpLayerService } from "src/app/services/http-layer.service";
import { Config } from "src/app/config/config";
import { AlertService } from '../../shared/alert.service';

@Component({
  selector: 'shared-slider',
  templateUrl: './shared-slider.component.html',
  styleUrls: ['./shared-slider.component.scss']
})
export class SharedSliderComponent implements OnInit {
  pageLabels: any;
  pageLanguage;
  copyIndustryList: any;

  JharkhandMap = {
    zoom: 6.6,
    lat: 22.3,
    lng: 85.5799
  };
  geoJsonObject: {};
  industryList = [];
  public industryView = {
    industry: null,
    slide: false,
    filterResultLoad: true,
  };

  constructor(
    private router: Router,
    public _http: HttpLayerService,
    public toaster: AlertService
  ) { }

  ngOnInit() {
    this.getTranslations();
    this.getIndustryFromFilters();
    let route = this.router.url.split('/');
    this.pageLanguage = route.pop();
  }

  getTo(page) {
    try {
      if (page === 'explore') {
        this.router.navigate(['/explore-ratings', this.pageLanguage]);
      } else if (page === 'about-program') {
        this.router.navigate(['/about-program', this.pageLanguage]);
      } else if (page === 'about-jspcb') {
        this.router.navigate(['/about-jspcb', this.pageLanguage]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['mainPageComponent']) {
            this.pageLabels = result['mainPageComponent'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  

  getIndustryFromFilters() {
    let input = {};
    input['fetchType'] = 'fetchAll';
    input['pageType'] = 'map_view';
    try {
      this._http.post(Config.API.GET_FILTERED_INDUSTRIES, input).subscribe(result => {
        if (result && result.status && result['data']['industries']) {
          this.copyIndustryList = result['data']['industries'];
          this.industryList = result['data']['industries'];
          // this.sortBy(0);
          for (let i = 0; i < this.industryList.length; i++) {
            const element = this.industryList[i];
            const icon = {
              url: '',
              scaledSize: {
                width: 25,
                height: 25,
              }
            };
            element['options'] = {
              color: 'white',
              fontWeight: 'bold',
              text: element['label'][0],
            };
            switch (element['rating']) {
              case '1':
                icon.url = 'assets/images/rating-1.png';
                element['icon'] = icon;
                break;
              case '2':
                icon.url = 'assets/images/rating-2.png';
                element['icon'] = icon;
                break;
              case '3':
                icon.url = 'assets/images/rating-3.png';
                element['icon'] = icon;
                break;
              case '4':
                icon.url = 'assets/images/rating-4.png';
                element['icon'] = icon;
                break;
              case '5':
                icon.url = 'assets/images/rating-5.png';
                element['icon'] = icon;
                break;

              default:
                break;
            }
          }
          this.industryView['filterResultLoad'] = false;
        } else {
          this.toaster.open('warning', '', 'Something went wrong, please try again.');
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  styleFunc(feature) {
    return ({
      clickable: false,
      fillColor: feature.getProperty('color'),
      strokeColor: '#779690',
      strokeWeight: 0.8
    });
  }
  onMouseOver(infoWindow, $event: MouseEvent) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }

}
