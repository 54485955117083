import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpLayerService } from "src/app/services/http-layer.service";
import { Config } from "src/app/config/config";
import { AlertService } from '../../shared/alert.service';

@Component({
  selector: 'kl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public password: any = {};
  pageLabels: any;
  pageLanguage;
  userLogged: Boolean = false;
  public allowed: boolean = true;
  public notAllowedOptions = ['industry'];
  isSideNaveOpen: boolean;
  activeNavTab: string;
  userType
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public _http: HttpLayerService,
    public toaster: AlertService,
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { this.authenticate(); this.getActiveHeader(); }
    });
  }

  public videoSector:boolean=false;
  ngOnInit() {
    this.authenticate();
    this.getActiveHeader();
  }
  getActiveHeader() {
    let route = this.router.url.split('/');
    route.pop();
    this.activeNavTab = route.pop();
    if(this.router.url.includes('public-news-updates-view')) {
      this.activeNavTab = 'public-news-updates';
    }
  }
  setLanguage() {
    try {
      let route = this.router.url.split('/');
      this.pageLanguage = route.pop();
      sessionStorage.setItem('language', this.pageLanguage);
      switch (this.pageLanguage) {
        case 'en':
          Config.JSON.INTERNATIONALIZATION = './assets/jsons/internationalization.json';
          Config.JSON.STP_DASHBOARD = './assets/jsons/stpSideBar.json';
          break;
        case 'hi':
          Config.JSON.STP_DASHBOARD = './assets/jsons/stpSideBar_hindi.json';
          Config.JSON.INTERNATIONALIZATION = './assets/jsons/internationalization_hindi.json';
          break;
        default:
          Config.JSON.STP_DASHBOARD = './assets/jsons/stpSideBar.json';
          Config.JSON.INTERNATIONALIZATION = './assets/jsons/internationalization.json';
          break;
      }
      this.getTranslations();
    } catch (error) {
      console.log(error);
    }
  }
  authenticate() {
    try {
      this.userType = sessionStorage.getItem('userType')
      for(let option of this.notAllowedOptions){
        if(option === this.userType){
          this.allowed = false;
        }
      }
      this.userLogged = Config.CONSTANTS.USER_TYPES.indexOf(sessionStorage.getItem('userType')) > -1 ? true : false;
      this.setLanguage();
    } catch (error) {
      console.log(error);
    }
  }
  navigate(route) {
    try {
      if (route === '/twitter') {
        window.location.href = './#/twitter/' + this.pageLanguage;
        window.location.reload();
      } else {
        this.router.navigate([route, this.pageLanguage.toLowerCase()]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  changeLang(language) {
    try {
      let route;
      route = this.router.url.split('/');
      this.pageLanguage = route.pop();
      this.pageLanguage = language;
      route = route.join('/');
      sessionStorage.setItem('language', language);
      this.router.navigate([route, this.pageLanguage.toLowerCase()]);
      setTimeout(() => {
        this.refresh();
      }, 500);
    } catch (error) {
      console.log(error);
    }
  }
  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['headerComponent']) {
            this.pageLabels = result['headerComponent'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  loginLogout() {
    try {
      if (this.userLogged) {
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('userType');
        sessionStorage.clear();
        this.toaster.open('success', 'Success', 'Logging out, Have a great day!');
        this.navigate('star-rating-portal/star-login');
        this.userLogged = false;
        if(!this.allowed){
          this.allowed = true;
        }
      } else {
        this.navigate('star-rating-portal/star-login');
      }
    } catch (error) {
      console.log(error);
    }
  }
  sideNavToggle() {
    try {
      if (!this.isSideNaveOpen) {
        this.isSideNaveOpen = true;
      } else {
        this.isSideNaveOpen = false;
      }
    } catch (error) {
      console.log(error);
    }
  }
  refresh(): void {
    window.location.reload();
  }
}
