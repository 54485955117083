import { Component, OnInit } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Config } from 'src/app/config/config';
// import { AlertService } from 'src/app/shared/alert.service';
// import { Toast, ToasterConfig, ToasterService } from 'angular2-toaster';
import * as bcrypt from 'bcryptjs';
import { AlertService } from 'src/app/alert-service.service';
@Component({
  selector: 'kl-portal-login',
  templateUrl: './portal-login.component.html',
  styleUrls: ['./portal-login.component.scss']
})
export class PortalLoginComponent implements OnInit {

  public activePage: any = "login";
  pageLabels: any;
  public resetData: any = {};
  public newUserData: any = {};
  public pageType: any = 'login';
  public resetPasswordItems: any =
    [
      {
        key: "userName",
        label: "User Name",
        type: "text",
        required: true,
        placeholder: "Enter Username"
      },
      {
        key: "newPassword",
        label: "New Password",
        type: "text",
        required: true,
        placeholder: "Enter New Password"
      }, {
        key: "confirmNewPassword",
        label: "Confirm New Password",
        type: "text",
        required: true,
        placeholder: "Confirm Password"
      },
    ]
  public loggedIn: any;
  public model = {
    userName: '',
    password: ''
  };
  public userDetails: any
  public toasterOptions = {
    animate: 'fade', maxShown: 3, toastTimeout: 4000,
  };
  // public config1: ToasterConfig = new ToasterConfig({
  //   positionClass: 'toast-top-right'
  // });
  constructor(
    public _http: HttpLayerService,
    private _router: Router,
    public toaster: AlertService,
  ) { }

  ngOnInit() {
    this.activePage = 'login';
    this.getTranslations();
  }
  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['loginComponent']) {
            this.pageLabels = result['loginComponent'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  /** pop up trigger */
  popUpTrigger(message, type) {
    try {
      if (type === 'success') {
        // const toast = {
        //   type: 'success',
        //   title: 'Success',
        //   body: 'User access denied for Star-Portal login',
        //   showCloseButton: true
        // };
        // // this.toasterService.pop(toast);
      } else if (type === 'error') {
        // const toast = {
        //   type: 'warning',
        //   title: 'Warning',
        //   body: 'Please fill all the Mandatory Details',
        //   showCloseButton: true
        // };
        // // this.toasterService.pop(toast);
      }
    } catch (error) {
      console.log(error);
    }
  }
  statusEmitter(event) {
    if (event && event.resetStatus) {
      this.activePage = 'login'
    }
  }
  loginPage() {
    const InputJson = {};
    InputJson['userName'] = this.model.userName;
    // InputJson['password'] = this.model.password;
    InputJson['password'] = this.hashPassword(this.model.password);
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    httpHeaders.set('Accept', 'q=0.8;application/json;q=0.9');
    const values = { headers: httpHeaders };
    try {
      this._http.loginService(InputJson, values).subscribe((response) => {
        const data = JSON.parse(window.atob(response));
        if (data.status && data.status === true) {
          // this.toaster.open('success', 'Success', data.message || 'Login Successful')
          sessionStorage.setItem('userDetails', JSON.stringify(data.data));
          sessionStorage.setItem('userId', data.data.userId);
          // sessionStorage.setItem('userType', 'industry');
          // sessionStorage.setItem('userName', this.model.userName);
          this.model.userName = '';
          this.model.password = '';
          document.getElementById('closeModalBtn').click()
          this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'))
          if (this.userDetails && this.userDetails.userRoleId) {
            switch (this.userDetails.userRoleId) {
              case 1:
                this._router.navigate(['/pages/landing/jspcb'])
                break;
              case 2:
                this._router.navigate(['/pages/landing/industry'])
                break;

              default:
                break;
            }
          }
          // this._router.navigate(['/star-rating-portal/', sessionStorage.getItem('language')]);
          // this.toaster.open('success', '', 'Star-Portal login successful');
          this.loggedIn = true;
        }
        else if (data.status === false && data.message === 'Wrong password') {
          this.toaster.open('warning', 'Warining',  data.message || 'Password Incorrect');
        }
        else {
          this.toaster.open('error', 'Failed',  data.message || 'Invalid');
          this.loggedIn = false;
        }
      });
    } catch (error) {
      console.log(error);
    }

  }
  hashPassword(password) {
    try {
      const deSaugars = '$2b$12$yGLTsi6dMwHAkNYvKaDM9e';
      let data = bcrypt.hashSync(password, deSaugars);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  resetPassword() {
    this.pageType = 'reset-password'
  }
  resetTriggerAPI() {
    try {
      const newPwd = this.hashPassword(this.resetData['newPassword'])
      const input = {
        action: 'reset password',
        newPassword: newPwd,
        userName: this.resetData['userName']
      };
      this._http.post(Config.API.LOGIN, input).subscribe((response) => {
        if (response && response.status) {
          // console.log(response);
        }
        else {
          // console.log('Failed');
        }
      })
    } catch (error) {
      console.error(Error)
    }
    this.pageType = 'login'
  }
  newUser() {
    this.pageType = 'new-user'
  }
  newUserAPI() {
    try {
      // const newPwd = this.hashPassword(this.resetData['newPassword'])
      const input = {
        "userName": "jspcb_admin",
        "password": this.hashPassword('jspcb_admin@123'),
        "userRole": 1,
        "userStatus": "Active",
        "siteIdList": [],
        "action": "new user"
      };
      // const input = {
      //   action: 'new user',
      //   userName: this.newUserData['userName'],
      //   password: this.hashPassword(this.newUserData['password']),
      //   userRole: this.newUserData['userRole']
      // };
      this._http.post(Config.API.ADD_USER, input).subscribe((res) => {
        const response = JSON.parse(res);
        if (res && res.status) {
          const toast = {
            type: 'success',
            title: response.status || 'Success',
            body: response.message || 'User access denied for Star-Portal login',
            showCloseButton: true
          };
          // // this.toasterService.pop(toast);
        }
        else {
          const toast = {
            type: 'warning',
            title: response.status || 'Failed',
            body: response.message || 'Failed to Add',
            showCloseButton: true
          };
          // // this.toasterService.pop(toast);
        }
      })

    } catch (error) {
      console.error(Error)
    }
    this.pageType = 'login'
  }

  alertMessage() {
    this.toaster.open("error", "failed", "FAILED TESTING")

  }
}
