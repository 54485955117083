import { Component, OnInit } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from 'src/app/config/config';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/alert.service';
import { post } from 'selenium-webdriver/http';

@Component({
  selector: 'kl-news-update-view',
  templateUrl: './news-update-view.component.html',
  styleUrls: ['./news-update-view.component.scss']
})
export class NewsUpdateViewComponent implements OnInit {
  public allPosts: any;
  public pageLabels: any;
  public postId: String = '';
  public postData:any = undefined;

  constructor(
    public _http: HttpLayerService,
    public _router: Router,
    private route: ActivatedRoute,
    public toaster: AlertService
  ) {
    this.route.params.subscribe((params) => {
      this.postId = params.postId;
      if(this.postId) {
        this.getAllPosts();
      }
    });
  }

  ngOnInit() {
    this.getTranslations();
  }
  
  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['newsUpdateComponent']) {
            this.pageLabels = result['newsUpdateComponent'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

getConvertedDate() {
  try {
    if(!this.postData || !this.postData.date) return '';
    var dateToConvert = new  Date(this.postData.date);
    return dateToConvert.toDateString();
  } catch(error) {
    console.log(error);
    return '';
  }
}

getTwitterURL(post) {
  let url =  'https://twitter.com/share?url=https%3A%2F%2Fjspcb.info/%23/public-news-updates-view/'+ post.post_id + "/en";
  return url;
}

getFacebookURL(post) {
  let url =  'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fjspcb.info/%23/public-news-updates-view/'+ post.post_id + "/en";
  return url;
}

  getAllPosts() {
    let input = {};
    input['edit_type'] = 'fetch';
    try {
      this._http
        .post(Config.API.POSTS_API, input)
        .subscribe(result => {
          if (result['tableData']) {
            this.allPosts = result['tableData']['bodyContent'];
            const postIndex = this.allPosts.findIndex((element) => {
              return element.post_id === this.postId;
            })
            if(postIndex > -1) {
              this.postData = this.allPosts[postIndex];
            }
          } else {
            this.toaster.open('error', '', 'Something went wrong, please try again.');
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

}
