import { Component, OnInit } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from 'src/app/config/config';
import { AlertService } from '../../shared/alert.service';
import { Subject } from 'rxjs';

@Component({
  selector: "kl-explore-ratings",
  templateUrl: './explore-ratings.component.html',
  styleUrls: ['./explore-ratings.component.scss']
})
export class ExploreRatingsComponent implements OnInit {
  selectedFilters: Object = {};
  industryList = [];
  public industryView = {
    industry: null,
    slide: false,
    filterResultLoad: true,
  };
  pageLabels: any;
  JharkhandMap = {
    zoom: 7.5,
    lat: 23.5102,
    lng: 85.5799
  };
  copyIndustryList: any;
  geoJsonObject: {};
  private drawChartsSubject: Subject<any> = new Subject<any>();
  constructor(
    public _http: HttpLayerService,
    public toaster: AlertService) { }

  ngOnInit() {
    this.getTranslations();
    this.getIndustryFromFilters();
    this.getGeoJsonObject();
  }

  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['exploreRatingsComponent']) {
            this.pageLabels = result['exploreRatingsComponent'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  getIndustryFromFilters() {
    let input = {};
    input['fetchType'] = 'fetchAll';
    input['pageType'] = 'map_view';
    try {
      this._http.post(Config.API.GET_FILTERED_INDUSTRIES, input).subscribe(result => {
        if (result && result.status && result['data']['industries']) {
          this.copyIndustryList = result['data']['industries'];
          this.industryList = result['data']['industries'];
          // this.sortBy(0);
          for (let i = 0; i < this.industryList.length; i++) {
            const element = this.industryList[i];
            const icon = {
              url: '',
              scaledSize: {
                width: 25,
                height: 25,
              }
            };
            element['options'] = {
              color: 'white',
              fontWeight: 'bold',
              text: element['label'][0],
            };
            switch (element['rating']) {
              case '1':
                icon.url = 'assets/images/rating-1.png';
                element['icon'] = icon;
                break;
              case '2':
                icon.url = 'assets/images/rating-2.png';
                element['icon'] = icon;
                break;
              case '3':
                icon.url = 'assets/images/rating-3.png';
                element['icon'] = icon;
                break;
              case '4':
                icon.url = 'assets/images/rating-4.png';
                element['icon'] = icon;
                break;
              case '5':
                icon.url = 'assets/images/rating-5.png';
                element['icon'] = icon;
                break;

              default:
                break;
            }
          }
          this.industryView['filterResultLoad'] = false;
        } else {
          this.toaster.open('warning', '', 'Something went wrong, please try again.');
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  reform() {
    try {
      this.industryList = this.filterIndustries(this.copyIndustryList);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 
   * @param filter industries based on selected Filters
   */
  filterIndustries(array) {
    try {
      this.industryView['filterResultLoad'] = false;
      let filter = {};
      for (const key in this.selectedFilters) {
        if (this.selectedFilters.hasOwnProperty(key)) {
          if (key !== 'star') {
            filter[key] = this.convertArray(this.selectedFilters[key], 'label');
          } else {
            filter[key] = this.convertArray(this.selectedFilters[key], 'key');
          }
        }
      }
      const filterKeys = ['categories', 'star', 'districts'];
      return array.filter((item) => {
        // dynamically validate all filter criteria
        return filterKeys.every(key => {
          // ignores an empty filter array
          if (!filter[key].length) {
            return true;
          }
          let resKey;
          switch (key) {
            case 'categories':
              resKey = 'category';
              break;
            case 'star':
              resKey = 'rating';
              break;
            case 'districts':
              resKey = 'district';
              break;

            default:
              break;
          }
          // return filter[key].includes(item[resKey]);
          return filter[key].some(element => element.toLowerCase() === item[resKey].toLowerCase())
        });
      });
    } catch (error) {
      console.log(error);
    }
  }

  convertArray(array, convert) {
    try {
      let bArray = [];
      for (let i = 0; i < array.length; i++) {
        let element = array[i];
        bArray.push(element[convert]);
      }
      return bArray;
    } catch (error) {
      console.log(error);
    }
  }

  clickedMarker(i) {
    try {
      this.industryView['slide'] = true;
      this.industryView['industry'] = this.industryList[i];
      this.drawChartsSubject.next(this.industryView['industry']['siteId'])
    } catch (error) {
      console.log(error);
    }
  }

  cloneObject(data) {
    try {
      return data ? JSON.parse(JSON.stringify(data)) : undefined;
    } catch (error) {
      console.log(error);
    }

  }

  onMouseOver(infoWindow, $event: MouseEvent) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }
  getGeoJsonObject() {
    this.geoJsonObject = {}
    try {
      this._http
        .get(Config.JSON.GET_JHARKHAND_GEO)
        .subscribe(result => {
          if (result) {
            this.geoJsonObject = result;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  styleFunc(feature) {
    return ({
      clickable: false,
      fillColor: feature.getProperty('color'),
      strokeColor: '#779690',
      strokeWeight: 0.8
    });
  }
}
