import { Component, OnInit  } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { LoaderService } from './loader-service.service';
import { LoaderState } from './loader-state';

@Component({
  selector: 'kl-loader-common',
  templateUrl: './loader-common.component.html',
  styleUrls: ['./loader-common.component.scss']
})
export class LoaderCommonComponent implements OnInit {
  show = false;
  private subscription: Subscription;
  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
    .subscribe((state: LoaderState) => {
      this.show = state.show;
    });
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
