import { Component, OnInit, Input } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from 'src/app/config/config';
import * as echarts from 'echarts';
import { Observable } from 'rxjs';

@Component({
  selector: 'kl-multi-search-rating',
  templateUrl: './multi-search-rating.component.html',
  styleUrls: ['./multi-search-rating.component.scss'],

})
export class MultiSearchRatingComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('industryList') industryList;
  // tslint:disable-next-line:no-input-rename
  @Input('industryView') industryView;
  public slide = false;
  variableSearchText = { label: '' };
  pageLabels: any;
  sortByValue: any;
  public toasterOptions = {
    animate: 'fade', maxShown: 3, toastTimeout: 4000,
  };
  isMobile: boolean;
  public showStarRatingGraph:boolean = false;
  public trendView = {
    starDateRange: [
      this.getYearMonth(new Date(), 0, 6, 0), this.getYearMonth(new Date(), 0, 0, 0)
    ]
  };
  public starGraphData;
  public seriesColorOptions = {
    "5" : {
      value: 5,
      name: 'Five',
      itemStyle: {
        color: '#019C7C',
      },
    },
    "4" : {
      value: 4,
      name: 'Four',
      itemStyle: {
        color: '#A2CF6E',
      },
    },
    "3": {
      value: 3,
      name: 'Three',
      itemStyle: {
        color: '#FFC107',
      },
    },
    "2": {
      value: 2,
      name: 'Two',
      itemStyle: {
        color: '#FF9800',
      },
    },
    "1" : {
      value: 1,
      name: 'One',
      itemStyle: {
        color: '#FF5722',
      },
    },
    "0": {
      // value: "null",
      name: 'zero',
      itemStyle: {
        color: '#fff',
      },
    }
  }
  @Input() drawTrendChart: Observable<void>;
  constructor(
    public _http: HttpLayerService
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.checkForResponsiveness();
    this.drawTrendChart.subscribe((siteId) => {
      setTimeout(() => {
        this.getStarGraphData(siteId);
      }, 300);
    });
  }

  getBadge(name) {
    try {
      let badge;
      badge = [];
      name.split(' ').forEach(element => {
        if (badge.length <= 1) {
          badge.push(element.split('')[0]);
        }
      });
      return badge.toString().replace(/,/g, '');
    } catch (error) {
      console.log(error);
    }
  }
  getRatingColor(rating) {
    try {
      const rate = Number(rating);
      let rateClass = 'veryPoor';
      if (rate === 5) {
        rateClass = 'veryGood';
      } else if (rate === 4) {
        rateClass = 'good';
      } else if (rate === 3) {
        rateClass = 'moderate';
      } else if (rate === 2) {
        rateClass = 'poor';
      }
      return rateClass;
    } catch (error) {
      console.log(error);
    }
  }
  goToIndustryCard(industry) {
    try {
      // console.log(industry);
      this.industryView['slide'] = true;
      setTimeout(() => {
        this.industryView['industry'] = industry;
        this.getStarGraphData(industry.siteId);
      }, 300);
    } catch (error) {
      console.log(error);
    }
  }

  getYearMonth(date, yAdd, mAdd, dAdd?) {
    try {
      if (!dAdd) {
        dAdd = 0;
      }
      let monthDate = new Date(date.setMonth(date.getMonth() - mAdd));
      const modifyDate = new Date(monthDate.getFullYear() + yAdd, monthDate.getMonth(), monthDate.getDate() + dAdd);
      return modifyDate;
    } catch (error) {
      console.log(error);
    }
  }

  getCharts() {
    try {
        const starChart = echarts.init(document.getElementById('starRatingTrendInRating'));
        const starConf = {
          title: {
            text: 'Star Rating trend',
            show: false,
          },
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: ['Five', 'Four', 'Three', 'Two', 'One'],
          },
          grid: {
            left: '3%',
            top: '22%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          toolbox: {
            show: false,
            orient: 'vertical',
            x: 'right',
            y: 'top',
            top: '-11',
            feature: {
              saveAsImage: {
                title: 'Save',
                show: true,
              },
            },
          },
          xAxis: {
            type: 'category',
            name: 'Date',
            boundaryGap: this.starGraphData.boundaryGap,
            data: this.starGraphData.xAxis,
            axisLabel: { show: true },
            nameLocation: 'end',
            nameTextStyle: {
              fontWeight: 'bold',
              fontSize: '16px'
            },
            nameGap: 15,
          },
          yAxis: {
            type: 'value',
            min: 0,
            max: 5,
            name: 'Rating',
            // nameLocation: 'center',
            nameTextStyle: {
              fontWeight: 'bold',
              fontSize: '16px'
            },
          },
          series: this.changeForBarColor(this.starGraphData.series),
        };
        starChart.setOption(starConf);
      
    } catch (error) {
      console.log(error);
    }
  }

  changeForBarColor(serviceSeries) {
    let series = [{ data: [], type: 'bar' }];
    if(!serviceSeries || serviceSeries.length < 1 || !serviceSeries[0].data) {
      return series;
    }
    for (let val of serviceSeries[0].data) {
      if(this.seriesColorOptions[val]) {
        series[0].data.push(this.seriesColorOptions[val]);
      } else {
        series[0].data.push(this.seriesColorOptions["0"]);
      }
    }
    return series;
  }

  getStarGraphData(id) {
    if (!id) return;
    const test_data = this.gobbleTrendDate(this.cloneObject(this.trendView));
    const input = {
      site_id: id,
      from_month: test_data['from_month'],
      to_month: test_data['to_month'],
      from_date: test_data['from_date'],
      to_date: test_data['to_date'],
      data_key: 'dataTrend',
    };
    this.showStarRatingGraph = false;
    this._http.post(Config.API.GET_STAR_GRAPH_DATA, input).subscribe(result => {
      if (result && result['dataTrend'] && result['dataTrend']['graphs'] && result['dataTrend']['graphs'].length > 0) {
        this.starGraphData = result['dataTrend']['graphs'][0];
      }
      this.showStarRatingGraph = true;
      setTimeout(() => {
        this.getCharts();
      }, 200);
    });
  }
  
  cloneObject(data) {
    try {
      return data ? JSON.parse(JSON.stringify(data)) : undefined;
    } catch (error) {
      console.log(error);
    }
  }

  gobbleTrendDate(trendView) {
    let changedTrendView = {};
    try {
      changedTrendView = trendView;
      changedTrendView['from_month'] = this.getSlashDateAndTime(trendView['starDateRange'][0], 'monthYear');
      changedTrendView['to_month'] = this.getSlashDateAndTime(trendView['starDateRange'][1], 'monthYear');
      changedTrendView['from_date'] = this.getSlashDateAndTime(trendView['starDateRange'][0]);
      changedTrendView['to_date'] = this.getSlashDateAndTime(trendView['starDateRange'][1]);
      return changedTrendView;
    } catch (error) {
      console.log(error);
    }
  }
  
  getSlashDateAndTime(date, type?) {
    let mDate
    try {
      date = new Date(date);
      // + ' ' + date.toTimeString().split(' ')[0]
      if (type) {
        mDate = this.zeroAdd(date.getMonth() + 1) + '/' + date.getFullYear();
      } else {
        mDate = this.zeroAdd(date.getMonth() + 1) + '/' + this.zeroAdd(date.getDate()) + '/' + date.getFullYear();
      }
      return mDate;
    } catch (error) {
      console.log(error);
    }
  }

  zeroAdd(num) {
    try {
      if (Number(num) < 10) {
        num = '0' + num;
      }
      return num;
    } catch (error) {
      console.log(error);
    }
  }

  backToIndustryList() {
    try {
      this.industryView['slide'] = false;
      setTimeout(() => {
        this.industryView['industry'] = null;
      }, 300);
    } catch (error) {
      console.log(error);
    }
  }
  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['multiSearchRatingComponent']) {
            this.pageLabels = result['multiSearchRatingComponent'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  sortBy(value) {
    try {
      this.sortByValue = value;
      if (this.sortByValue === 0) {
        this.industryList.sort(this.dynamicSort('label', 'alpha'));
      } else if (this.sortByValue === 1) {
        this.industryList.sort(this.dynamicSort('label', 'alpha')).reverse();
      } else if (this.sortByValue === 2) {
        this.industryList.sort(this.dynamicSort('rating', 'num')).reverse();
      } else if (this.sortByValue === 3) {
        this.industryList.sort(this.dynamicSort('rating', 'num'));
      } else {
        this.industryList.sort(this.dynamicSort('category', 'alpha'));
      }
    } catch (error) {
      console.log(error);
    }
  }
  dynamicSort(property, checkType) {
    try {
      return function (a, b) {
        if (checkType === 'alpha') {
          return a[property].localeCompare(b[property]);
        } else {
          return a[property] - b[property];
        }
      };
    } catch (error) {
      console.log(error);
    }
  }
  toggleFilterBody() {
    try {
      if (!this.isMobile) {
        return;
      }
      const filterList = document.getElementById('filterResult');
      filterList.classList.toggle('hide');
      const applyFilter = document.getElementById('applyFilter');
      applyFilter.classList.toggle('show');
    } catch (error) {
      console.log(error);
    }
  }
  checkForResponsiveness() {
    if (window.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
