import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ActivationStart, RouterOutlet } from '@angular/router';
import { HttpLayerService } from "src/app/services/http-layer.service";
import { Config } from "src/app/config/config";
import { AlertService } from '../../shared/alert.service';

@Component({
  selector: 'nav-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class NavHeaderComponent implements OnInit {
  @ViewChild(RouterOutlet) outlet: RouterOutlet;

  public password: any = {};
  public videoURL:any="https://www.youtube.com/embed/LFoz8ZJWmPs";
  pageLabels: any;
  pageLanguage;
  public searchPath;
  public searchValue: any;
  userLogged: Boolean = false;
  public allowed: boolean = true;
  public showDropdown: boolean = false;
  showAbout = false;
  public notAllowedOptions = ['industry'];
  isSideNaveOpen: boolean;
  activeNavTab: string;
  userType;
  public userDetails: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public _http: HttpLayerService,
    public toaster: AlertService,
  ) {
    // const newURL = window.location.href.split('/')
    // this.activeNavTab = newURL[newURL.length-1]
    // console.log(this.activeNavTab)
    // this.route.paramMap.subscribe(paramMap => {
    //   // this.bankName = paramMap.get('bank');
    //   console.log(paramMap)
    // })
    // this.router.events.subscribe((ev) => {

    //   if (ev instanceof NavigationEnd) { 
    //     this.authenticate(); this.getActiveHeader();
    //     const route=ev.url.split('/');
    //     // this.searchPath = route[1];
    //     if(route.length>0){
    //       // this.activeNavTab=route[1];
    //       this.activeNavTab = undefined;
    //       route[1] === 'main' ? this.navigate('home') : this.navigate(route[1]);
    //       this.activeNavTab = route[1] === 'main' ? 'home': route[1];
    //     }

    //   }

    //   // if (ev instanceof ActivationStart) {

    //   //   console.log(ev.snapshot.outlet === 'primary')
    //   //   this.outlet.deactivate();
    //   // }
    // });
    if (sessionStorage.getItem('userDetails')) {
      this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'))
      // this.activeNavTab=
      // if(this.userDetails.userRoleId === 2){
      //   this.activeNavTab=this.industryTabs[0].route
      // }
    }
  }

  public industryTabs: any = []
  public jspcbTabs: any = []
  public navTabs: any = []
  ngOnInit() {
    this.getTabsLists()
    const newURL = window.location.href.split('/')
    this.activeNavTab = newURL[newURL.length - 1]
    // this.authenticate();
    // this.getActiveHeader();
  }
  getTabsLists() {
    try {
      this._http.get(Config.JSON.TABS_LIST).subscribe((response) => {
        this.navTabs = response.navTabs;
        this.jspcbTabs = response.jspcbTabs;
        this.industryTabs = response.industryTabs
      })
    } catch (error) {
      console.log(Error);
    }
  }
  getActiveHeader() {
    let route = this.router.url.split('/');
    // route.pop();
    this.activeNavTab = route[1];
    // route.pop();
    // this.activeNavTab = route.pop();
    if (this.router.url.includes('public-news-updates-view')) {
      this.activeNavTab = 'public-news-updates';
    }
  }
  setLanguage() {
    try {
      let route = this.router.url.split('/');
      this.pageLanguage = route.pop();
      sessionStorage.setItem('language', this.pageLanguage);
      switch (this.pageLanguage) {
        case 'en':
          Config.JSON.INTERNATIONALIZATION = './assets/jsons/internationalization.json';
          Config.JSON.STP_DASHBOARD = './assets/jsons/stpSideBar.json';
          break;
        case 'hi':
          Config.JSON.STP_DASHBOARD = './assets/jsons/stpSideBar_hindi.json';
          Config.JSON.INTERNATIONALIZATION = './assets/jsons/internationalization_hindi.json';
          break;
        default:
          Config.JSON.STP_DASHBOARD = './assets/jsons/stpSideBar.json';
          Config.JSON.INTERNATIONALIZATION = './assets/jsons/internationalization.json';
          break;
      }
      this.getTranslations();
    } catch (error) {
      console.log(error);
    }
  }
  authenticate() {
    try {
      this.userType = sessionStorage.getItem('userType')
      for (let option of this.notAllowedOptions) {
        if (option === this.userType) {
          this.allowed = false;
        }
      }
      this.userLogged = Config.CONSTANTS.USER_TYPES.indexOf(sessionStorage.getItem('userType')) > -1 ? true : false;
      // this.setLanguage();
    } catch (error) {
      console.log(error);
    }
  }
  navigate(route) {
    try {
      if (route && typeof (route) === 'object') {
        this.searchValue = '';
        this.activeNavTab = route.key
        this.router.navigate([route.route]);
      } else {

        if (route === '/twitter') {
          window.location.href = './#/twitter/' + this.pageLanguage;
          window.location.reload();
        } else {
          this.searchValue = '';
          let routi = route.split('/')
          this.activeNavTab = routi[routi.length - 1]
          this.router.navigate([route]);
          // this.router.navigate([route, this.pageLanguage.toLowerCase()]);
        }
      }


    } catch (error) {
      console.log(error);
    }
  }
  searchElement(route) {
    if (this.searchValue) {
      this._http.searchElement = this.searchValue;
      if (this.searchPath == 'searchFilter') {
        this.router.navigate(['home', this.pageLanguage.toLowerCase()]);
      }
      setTimeout(() => {
        this.router.navigate([route, this.pageLanguage.toLowerCase()]);
      }, 20);
    }
  }
  changeLang(language) {
    try {
      let route;
      route = this.router.url.split('/');
      this.pageLanguage = route.pop();
      this.pageLanguage = language;
      route = route.join('/');
      sessionStorage.setItem('language', language);
      this.router.navigate([route, this.pageLanguage.toLowerCase()]);
      setTimeout(() => {
        this.refresh();
      }, 500);
    } catch (error) {
      console.log(error);
    }
  }
  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['headerComponent']) {
            this.pageLabels = result['headerComponent'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  loginLogout() {
    try {
      if (this.userLogged) {
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('userType');
        this.toaster.open('success', '', 'Logging out, Have a great day!');
        this.navigate('star-rating-portal/star-login');
        this.userLogged = false;
        if (!this.allowed) {
          this.allowed = true;
        }
      } else {
        this.navigate('star-rating-portal/star-login');
      }
    } catch (error) {
      console.log(error);
    }
  }
  sideNavToggle() {
    try {
      if (!this.isSideNaveOpen) {
        this.isSideNaveOpen = true;
      } else {
        this.isSideNaveOpen = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  redirect() {
    window.open("http://jsac.jharkhand.gov.in/pollution/");
  }
  refresh(): void {
    window.location.reload();
  }

  openLogin() {
    document.getElementById('loginModalBtn').click();
    this.showPage = true;
  }
  closeLogin() {
    this.showPage = false;
    document.getElementById('loginModalBtn').click();
  }

  public showPage: boolean = false;

  logout() {
    const payload:any={
      userId:sessionStorage.getItem('userId')
    }
    try {
      this._http.get(Config.API.LOGOUT).subscribe((response) => {
        if(response.status===true){
          if (sessionStorage.getItem('userDetails')) {
            if(this.activeNavTab==='home'){
              location.reload()
            }
            sessionStorage.clear()
            this.toaster.open('success', response.message,'Success');
          }
          this.navigate('/home');
        }
      });
    } catch (error) {
      console.error(error)
    }
  }
}
