import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from 'src/app/config/config';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { AlertService } from 'src/app/shared/alert.service';

@Component({
  selector: 'kl-star-rating-methodology',
  templateUrl: './star-rating-methodology.component.html',
  styleUrls: ['./star-rating-methodology.component.scss']
})
export class StarRatingMethodologyComponent implements OnInit {
  public methodologyPage: any;
  yStarRating: any;
  pageload: boolean = false;;
  constructor(public _http: HttpLayerService,
    public toaster: AlertService,
    private router: Router,) { }

  ngOnInit() {
    this.getTranslations();
    this.getContent();
  }

  getContent() {
    try {
      this._http.get(Config.JSON.GET_METHODOLOGY_CONTENT).subscribe((result) => {
        if (result && result['methodologyPage']) {
          this.methodologyPage = result['methodologyPage']
        } else {
          this.toaster.open('failed', 'Failed', 'Failed to Fetch Data')
        }
      })

    } catch (error) {
      console.log(error)
    }
  }
  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['aboutStarProgramComponent']) {
            this.yStarRating = result['aboutStarProgramComponent']['yStarRating'];
            // this.jharkhand = result['aboutStarProgramComponent']['jharkhand'];
            this.pageload = true;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  public listOfCategories:any=[
    {
      label:"Aluminium",
      icon:"ALUMINIUM.png"
    },
    // {
    //   label: "Caustic Soda",
    //   icon: "CAUSTIC SODA.png"
    // },
    // {
    //   label: "CBMWTF",
    //   icon: "CBMWTF.png"
    // },
    {
      label: "Cement",
      icon: "CEMENT.png"
    },
    // {
    //   label: "Chemical",
    //   icon: "CHEMICAL.png"
    // },
    {
      label: "Chlor Alkali",
      icon: "CHLOR ALKALI.png"
    },
    {
      label: "Copper",
      icon: "COPPER.png"
    },
    {
      label: "Distillary",
      icon: "DISTILLARY.png"
    },
    {
      label: "Dye and Dye Intermediate",
      icon: "DYES AND DYE INTERMEDIATED.png"
    },
    // {
    //   label: "Ferro Alloys",
    //   icon: "FERRO ALLOYS.png"
    // },
    {
      label: "Fertilizers",
      icon: "FERTILIZER.png"
    },
    // {
    //   label: "Forgings and Machining Plant",
    //   icon: "FORGINGS AND MACHINING PLANT.png"
    // },
    // {
    //   label: "Industrial Carbon",
    //   icon: "INDUSTRIAL CARBON.png"
    // },
    {
      label: "Iron and Steel",
      icon: "IRON AND STEEL.png"
    },
    // {
    //   label: "Manufacture",
    //   icon: "MANUFACTURE.png"
    // },
    // {
    //   label: "Metalogic Pellet",
    //   icon: "METALOGICPELLET.png"
    // },
    // {
    //   label: "Metals and Alloys",
    //   icon: "METALS AND ALLOYS.png"
    // },
    {
      label: "Oil Refinery",
      icon: "OIL REFINERY.png"
    },
    {
      label: "Pesticides",
      icon: "PESTICIDE.png"
    },
    {
      label: "Petrochemical",
      icon: "PETROCHEMICAL.png"
    },

    {
      label: "Pharmaceuticals",
      icon: "PHARMACEUTICAL.png"
    },
    {
      label: "Power Plants",
      icon: "POWER PLANT.png"
    },
    {
      label: "Pulp and Paper",
      icon: "PULP AND PAPER.png"
    },
    {
      label: "Sugar",
      icon: "SUGAR.png"
    },
    {
      label: "Tannery",
      icon: "TANNERY.png"
    },
    // {
    //   label: "Thermal Power Plant",
    //   icon: "THERMAL POWER PLANT.png"
    // },
    {
      label: "Zinc",
      icon: "ZINC.png"
    }

  ]
}
