import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }
  public footerData:any;
  ngOnInit() {
    this.footerData=['Tab1','Tab2','Tab3']
  }
 
  openExternal(event:any){
    if (event ==='jpscb'){
      window.open('https://www.jspcb.nic.in/','_blank')
    } else if (event === 'usaid') {
      window.open('https://www.usaid.gov/', '_blank')
    } else if (event === 'macarthur') {
      window.open('https://www.macfound.org/', '_blank')
    } else if (event === 'epicIndia') {
      window.open('https://epic.uchicago.in/', '_blank')
    }
  }
} 
