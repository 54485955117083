import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from 'src/app/config/config';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: "kl-multi-search-checkbox",
  templateUrl: './multi-search-checkbox.component.html',
  styleUrls: ['./multi-search-checkbox.component.scss']
})
export class MultiSearchCheckboxComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('selectedFilters') selectedFilters: Object = {};
  // tslint:disable-next-line:no-output-rename
  @Output('reloadIndustries') reloadIndustries = new EventEmitter();
  screenHeight: number;
  screenWidth: number;
  variableSearchText = { label: '' };
  public controls: boolean = true;

  sideMenus: any = [];

  // @Input() menuList;
  public toggleFlag = false;
  openedKey: any = [];
  isActive = false;
  showMenu = '';
  public showSubMenu: Boolean = false;
  public activated: String = 'overview';
  public menuList = [];
  public expandMore = false;
  public filterData: [];
  pageLabels: any;
  isMobile: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor(private _http: HttpLayerService, private http: HttpClient) { }
  ngOnInit() {
    this.setFilterData();
    this.getTranslations();
    this.checkForResponsiveness();
  }

  public hasData(): boolean {
    return (this.filterData != null && this.filterData.length > 0);
  }

  setFilterData() {
    try {
      this._http
        .get(Config.API.FILTER_DATA)
        .subscribe(responseJson => {
          if (responseJson.status) {
            this.filterData = responseJson.data;
            this.initializeFilter();
          } else {
            this.filterData = undefined;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  initializeFilter() {
    try {
      if (this.filterData['filterDrops'].length >= 0) {
        for (let i = 0, len = this.filterData['filterDrops'].length; i < len; i++) {
          const dropDown = this.filterData['filterDrops'][i];
          // this.selectedFilters[dropDown['key']] = this.filterData['filterDrops'][i]['subFilterDrops']; 
          // this.filterData['filterDrops'][i]['subFilterDrops'].forEach(filter => {
          //   filter['variableSearchText'] = { label: '' };
          // });
          this.selectedFilters[dropDown['key']] = [];
          this.openedKey.push(dropDown['key']);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  selectOrDeselectOption(event, filterKey, subFilter) {
    try {
      const value: boolean = event.target.checked;
      if (!this.selectedFilters) {
        this.selectedFilters = {};
      }
      if (filterKey in this.selectedFilters) {
        if (value) {
          this.selectedFilters[filterKey].push(subFilter);
        } else {
          this.selectedFilters[filterKey] = this.selectedFilters[filterKey].filter(
            e => e.key !== subFilter.key,
          );
        }
      } else if (value) {
        this.selectedFilters[filterKey] = [subFilter];
      }
      this.reloadIndustries.emit();
    } catch (error) {
      console.log(error);
    }
  }

  selectAll(event, filterKey, i) {
    try {
      const value: boolean = event.target.checked;
      this.selectedFilters[filterKey] = [];
      if (value) {
        this.selectedFilters[filterKey] = this.filterData['filterDrops'][i]['subFilterDrops'];
      }
      this.reloadIndustries.emit();
    } catch (error) {
      console.log(error);
    }
  }
  toggleFilter(category) {
    try {
      const index = this.openedKey.indexOf(category.key); // <-- Not supported in <IE9
      if (index !== -1) {
        this.openedKey.splice(index, 1);
      } else {
        this.openedKey.push(category.key);
      }
    } catch (error) {
      console.log(error);
    }
  }

  getCheckboxValue(category, subcategory) {
    try {
      if (this.selectedFilters) {
        return this.checkArrayOfObjects(this.selectedFilters[category], 'key', subcategory) > -1 ? true : false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  ensure objects have the property,
   * @param array array to be iterated for the object 'item'
   * @param propertyToBeChecked unique property for item
   * @param item item which is a object
   */
  checkArrayOfObjects(array, propertyToBeChecked, item) {
    try {
      let index = -1;
      for (let i = 0, len = array.length; i < len; i++) {
        if (array[i][propertyToBeChecked] === item[propertyToBeChecked]) {
          index = i;
          break;
        }
      }
      return index;
    } catch (error) {
      console.log(error);
    }
  }

  resetCategory(event, category) {
    try {
      event.stopPropagation();
      if (category) {
        this.selectedFilters[category] = [];
      }
    } catch (error) {
      console.log(error);
    }
  }
  filterAction(action) {
    try {
      if (this.filterData['filterDrops']) {
        if (action === 'expand') {
          for (let i = 0; i < this.filterData['filterDrops'].length; i++) {
            this.openedKey.push(this.filterData['filterDrops'][i]['key']);
          }
        } else if (action === 'collapse') {
          this.openedKey = [];
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  getBadge(name) {
    try {
      let badge;
      badge = [];
      name = name? name.trim() : name;
      name.split(' ').forEach(element => {
        if (badge.length < 1) {
          badge.push(element.split('')[0]);
        }
      });
      return badge.toString().replace(/,/g, '');
    } catch (error) {
      console.log(error);
    }
  }

  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['multiSearchCheckboxComponent']) {
            this.pageLabels = result['multiSearchCheckboxComponent'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  getRatingColor(rating) {
    try {
      const rate = Number(rating);
      let rateClass = 'veryPoor';
      if (rate === 5) {
        rateClass = 'veryGood';
      } else if (rate === 4) {
        rateClass = 'good';
      } else if (rate === 3) {
        rateClass = 'moderate';
      } else if (rate === 2) {
        rateClass = 'poor';
      }
      return rateClass;
    } catch (error) {
      console.log(error);
    }
  }
  toggleFilterBody() {
    try {
      if (!this.isMobile) {
        return;
      }
      const applyFilter = document.getElementById('applyFilter');
      applyFilter.classList.toggle('hide');
      const filterList = document.getElementById('filterResult');
      filterList.classList.toggle('show');
    } catch (error) {
      console.log(error);
    }
  }
  checkForResponsiveness() {
    if (window.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
