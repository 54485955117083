import { Component, EventEmitter, OnInit, Output } from '@angular/core';
// import { Toast, ToasterService } from 'angular2-toaster';
import { AlertService } from 'src/app/shared/alert.service';

@Component({
  selector: 'kl-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @Output() statusEmitter = new EventEmitter<any>()


  constructor(
    public toaster: AlertService,
    // /* private toasterService: ToasterService */
  ) { }

  ngOnInit() {
  }
  public activeStep: any = 1;
  public loginPage: any = 1;
  public resetData: any = {};
  forwardToStep2() {
    this.activeStep = 2;
  }
  forwardToStep3() {
    this.activeStep = 3;
  }
  confirmResetPassword(){
    this.loginPage = 1
    // this.toaster.open('success', 'Success', 'Password Reset Sucessfully.');
    // const toast = {
    //   type: 'success',
    //   title: 'Success',
    //   body: 'Password Reset Sucessfully.',
    //   showCloseButton: true
    // };
    // // this.toasterService.pop(toast);

    this.statusEmitter.emit({"resetStatus":true})
  }
}
