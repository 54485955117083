import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kl-am-i-at-risk',
  templateUrl: './am-i-at-risk.component.html',
  styleUrls: ['./am-i-at-risk.component.scss']
})
export class AmIAtRiskComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
