import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { MainPageComponent } from './main-page/main-page.component';
import { NewsUpdateComponent } from './news-update/news-update.component';
import { ExploreRatingsComponent } from './explore-ratings/explore-ratings.component';
import { AboutStarPgmComponent } from './about-star-pgm/about-star-pgm.component';
import { ContactComponent } from './contact/contact.component';
import { TwitterComponent } from './twitter/twitter.component';
import { WhyJarkhandComponent } from './why-jarkhand/why-jarkhand.component';
import { WhyStarRatingComponent } from './why-star-rating/why-star-rating.component';
import { NewsUpdateViewComponent } from './news-update-view/news-update-view.component';
import { AboutJspcbComponent } from './about-jspcb/about-jspcb.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { IndustryRatingComponent } from './industry-rating/industry-rating.component';
import { CityRatingComponent } from './city-rating/city-rating.component';
import { AmIAtRiskComponent } from './am-i-at-risk/am-i-at-risk.component';
import { IndustryViewComponent } from './industry-view/industry-view.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { StarRatingMethodologyComponent } from './star-rating-methodology/star-rating-methodology.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { FAQComponent } from './faq/faq.component';
import { LandingModule } from './landing/landing.module';
import { AuthGuard } from '../auth.guard';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
      }, {
        path: 'risk/:id',
        component: AmIAtRiskComponent,
      }, {
        path: 'main/:id',
        component: HomeComponent,
      }, {
        path: 'city-rating/:id',
        component: CityRatingComponent
      }, {
        path: 'industry-rating',
        component: IndustryRatingComponent,
      },
      {
        path: 'public-news-updates',
        component: NewsUpdateComponent,
      },
      {
        path: 'methodology',
        component: StarRatingMethodologyComponent,
      },
      {
        path: 'searchFilter',
        component: SearchFilterComponent,
      },
      {
        path: 'public-news-updates-view/:postId',
        component: NewsUpdateViewComponent,
      },
      {
        path: 'explore-ratings/:id',
        component: ExploreRatingsComponent,
      },
      {
        path: 'about-program',
        component: AboutStarPgmComponent,
      },
      {
        path: 'about-jspcb',
        component: AboutJspcbComponent,
      },
      {
        path: 'industry-view/:info/:id',
        component: DashboardComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'twitter',
        component: TwitterComponent,
      },
      {
        path: 'whyJarkhand',
        component: WhyJarkhandComponent
      },
      {
        path: 'dashboard',
        component: DashboardNewComponent
      },
      {
        path: 'whyStarRaring',
        component: WhyStarRatingComponent
      },
      {
        path: 'login',
        component: LoginPageComponent
      },
      {
        path: 'faq',
        component: FAQComponent
      },
      // {
      //   path: 'industry-view/:info/:id',
      //   component: IndustryViewComponent
      // }
      {
        path: 'landing',
        loadChildren: './landing/landing.module#LandingModule', canActivate: [AuthGuard]
      }


    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {

}
