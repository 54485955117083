import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import 'rxjs/Rx';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/finally';
import { Config } from '../config/config';
import { LoaderService } from '../shared/loader/loader-service.service';
import { AlertService } from '../shared/alert.service';

@Injectable({
  providedIn: 'root',
})
export class HttpLayerService {
  toggled = false;
  searchElement:any;
  _hasBackgroundImage = true;
  public showErrorDialog = new BehaviorSubject({ status: false, errorMsg: '' });
  public getErrorDialogStatus = this.showErrorDialog.asObservable();

  private monitoring = {
    pendingRequestsNumber: 0,
  };
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  pageLanguage: any = 'en';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private loaderService: LoaderService,
    public toaster: AlertService,
  ) { }

  get(url: string, options?: any, loader: boolean = true): Observable<any> {
    try {
      if (loader) {
        this.showLoader();
      }
      return this.handleResponse(this._http.get(url)).finally(() => {
        if (loader) {
          this.monitoring.pendingRequestsNumber--;
        }
        this.hideLoader();
      });
    } catch (error) {
      console.log(error);
    }
  }

  loginService(body, values): Observable<any> {
    try {
      this.showLoader();
      let route;
      route = this._router.url.split('/');
      this.pageLanguage = route.pop();
      // const payLoad = btoa(JSON.stringify(body));
      const payLoad = JSON.stringify(body);
      let headerJson;
      headerJson = values ? values : {
        'Content-Type': 'text/plain',
      };
      const headersConfig = new HttpHeaders(headerJson);
      const requestOptions: any = { headersConfig, responseType: 'text' };
      return this._http.post(Config.API.LOGIN, payLoad, requestOptions).catch((err, source) => {
        this._router.navigate(['home']);
        this.toaster.open('warning', '', 'Something went wrong, please try again.');
        // console.log(err, source);
        return Observable.empty();
      }).finally(() => {
        this.monitoring.pendingRequestsNumber--;
        this.hideLoader();
      });
    } catch (error) {
      console.log(error);
      this.toaster.open('warning', '', 'Something went wrong, please try again.');
      this._router.navigate(['home']);
    }

  }


  comonnonGetService(url) {
    return this._http.get(url)
      // .subscribe((res:Response) => res.json() ,(err:any) => {
      //   console.log(err);
      // })
      // .catch((error:any) => Observable.throw(error.json().error || 'Server error'));
      ;
  }
  postTranslate(url: string, data: any, values?: any, loader: boolean = true): Observable<any> {
    try {
      if (loader) {
        this.showLoader();
      }
      let body = new FormData();
      body.append('key', data.key);
      body.append('q', data.q);
      body.append('target', data.target);
      return this.handleResponse(this._http.post(url, body, values)).finally(() => {
        if (loader) {
          this.monitoring.pendingRequestsNumber--;
        }
        this.hideLoader();
      });
    } catch (error) {
      console.log('error');
    }
  }

  post(url: string, data: any = {}, values?: any, loader: boolean = true): Observable<any> {
    try {
      const httpHeaders = new HttpHeaders();
      httpHeaders.set('Content-Type', 'application/json');
      values = { headers: httpHeaders };
      if (loader) {
        this.showLoader();
      }
      const userDetails = {
        // userType: sessionStorage.getItem('userType'),
        // userName: sessionStorage.getItem('userName'),
      };
      data = Object.assign(data, userDetails);
      const body = JSON.stringify(data);
      return this.handleResponse(this._http.post(url, body, values)).finally(() => {
        if (loader) {
          this.monitoring.pendingRequestsNumber--;
        }
        this.hideLoader();
      });
    } catch (error) {
      console.log('error');
    }
  }
  postText(url: string, data: any = {}, options?: any, loader: boolean = true): Observable<any> {
    try {
      let headerJson;
      headerJson = options ? options : {
        'Content-Type': 'text/plain',
      };
      const headersConfig = new HttpHeaders(headerJson);
      const requestOptions: any = { headersConfig, responseType: 'text' };
      if (loader) {
        this.showLoader();
      }
      const userDetails = {
        userType: sessionStorage.getItem('userType'),
        user: sessionStorage.getItem('userName'),
      };
      const temp = Object.assign(userDetails, data);
      const body = JSON.stringify(data);
      return this.handleResponse(this._http.post(url, body, requestOptions)).finally(() => {
        if (loader) {
          this.monitoring.pendingRequestsNumber--;
        }
        this.hideLoader();
      });
    } catch (error) {
      console.log('error');
    }
  }

  handleResponse(observable: Observable<any>, isBackgroundServiceCall: boolean = false): Observable<any> {
    return observable.catch((err, source) => {
      if (err.status === 401) {
        this._router.navigate(['/main', this.pageLanguage]);
        return Observable.empty();
      } else {
        this.toaster.open('warning', '', 'Something went wrong, please try again.');
        return Observable.throw(err);
      }
    }).finally(() => {
      if (this.monitoring.pendingRequestsNumber === 0) {
        this.hideLoader();
      }
    });
  }
  public handleError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg);
    return Observable.throw(errMsg);
  }

  setShowErrorDialog(obj) {
    this.showErrorDialog.next(obj);
  }

  showLoader(isService = true): void {
    if (isService) {
      this.monitoring.pendingRequestsNumber++;
    }
    this.loaderService.show();
  }
  hideLoader(): void {
    if (this.monitoring.pendingRequestsNumber === 0) {
      this.loaderService.hide();
    }
  }
}