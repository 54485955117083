import { Component, OnInit } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from 'src/app/config/config';
import { AlertService } from '../../shared/alert.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as echarts from 'echarts';
import * as L from 'leaflet';
// import { Toast, ToasterConfig, ToasterService } from 'angular2-toaster';

@Component({
  selector: 'kl-industry-rating',
  templateUrl: './industry-rating.component.html',
  styleUrls: ['./industry-rating.component.scss']
})
export class IndustryRatingComponent implements OnInit {
  public bodyData: any = [
    {
      data1: 100,
      data2: 200,
      data3: 300
    },
    {
      data1: 400,
      data2: 500,
      data3: 600
    },
    {
      data1: 700,
      data2: 800,
      data3: 900
    }
  ]
  // public chartOptions: any = {
  //   legend: {
  //     data: ['Total Funds', 'Funds Utilized', 'Funds Not Utilized']
  //   },
  //   xAxis: {
  //     type: 'category',
  //     data: ['Total Funds', 'Finds Utilized', 'Finds Not Utilized']
  //   },
  //   yAxis: {
  //     type: 'value',
  //     splitLine: {
  //       show: false
  //     }
  //   },
  //   series: [{
  //     data: [{ value: 120, name: 'Total Funds', itemStyle: { color: "#000046" } },
  //     { value: 50, name: 'Funds Utilized', itemStyle: { color: "#1CB5E0" } },
  //     { value: 70, name: 'Funds Not Utilized', itemStyle: { color: "#ccc" } },],
  //     type: 'bar'
  //   }]
  // };
  selectedFilters: Object = {
    categories: '',
    districts: '',
    star: '',
    industriesPerPage: 10,
    parameter: 'pm'
  };
  searchText;
  industryList = [];
  public tableData = null;
  public reportDetails: any = {};
  public tableRecords:any;
  public filterDetails: any = {
    selectedYear:new Date().getFullYear(),
    selectedMonth: new Date().getMonth(),
    selectedIndustry:null,
    selectedDistrict:null,
    selectedCategory:null,
    selectedRating:null,
    selectedParameter:null
  };
  public dropdownsData:any={};
  public reportTable = false;
  public data: any;
  // public recordsToShow='10';
  public dtOptions = {
    // pagingType: 'full_numbers',
    pagingType: 'simple_numbers',
    numbers_length: 5,
    searching: false,
    scrollY: '100vh',
    scrollCollapse: true,
    language: {
      emptyTable: "Data is not available for the selected filters"
    }
  };

  dtOptions2 = {
    pagingType: 'full_numbers',
    language: {
      emptyTable: "No 5 star rated industries found"
    }
  };
  public monthYear2: any = {};
  public year: any = [];
  private drawChartsSubject: Subject<any> = new Subject<any>();
  public headerContent = [{
    "key": "label",
    "label": "Indusry"
  }, {
    "key": "district",
    "label": "District"
  }, {
    "key": "category",
    "label": "Category"
  }];
  public filter: any = false;
  public filterData: any = null;
  public industryView = {
    industry: null,
    slide: false,
    filterResultLoad: true,
  };
  pageLabels: any;
  JharkhandMap = {
    zoom: 7,
    lat: 23.5102,
    lng: 85.5799
  };
  copyIndustryList: any;
  geoJsonObject: {};

  public tableData2 = {
    headerContent: [],
    bodyContent: [],
  };
  public displayValue: any;
  public dataViewActive: any;
  isTableDataLoaded: boolean = false;
  public pageLanguage: any;
  public jarkhandRestriction: any = {
    latLngBounds: {
      east: 90.58,
      north: 27.14,
      south: 19.57,
      west: 81.20
    },
    strictBounds: true
  };

  // public config1: ToasterConfig = new ToasterConfig({
  //   positionClass: 'toast-top-right'
  // });

  public ratingItems: any = [
    {
      "key": "industryRating",
      "label": "Industry Rating"
    },
    {
      "key": "stationRating",
      "label": "Station Rating"
    }
  ]

  public dataView: any = [
    {
      "key": "lineGraph",
      "label": "Line Graph"
    },
    {
      "key": "table",
      "label": "Table"
    }
  ]
  constructor(
    public _http: HttpLayerService,
    public toaster: AlertService, private router: Router,/* private toasterService: ToasterService */) { }

  ngOnInit() {
    this.getMonthAndYear()
    // this.getIndustryListData()
    if (screen.width < 450) {
      this.dtOptions.pagingType = 'simple';
    }
    this.pageLanguage = sessionStorage.getItem('language');
    // this.getIndustryList()
    this.getIndustryFromFilters();
    this.dropdownData();
    this.setFilterData();
   
    this.selectedIndustry();
    // this.initMap()
  }

  getIndustryList() {
    try {
      this._http.post(Config.API.jspcbIndustryDetails, {}).subscribe(result => {
        if (result.status) {
          this.tableData2 = result;
          this.isTableDataLoaded = true;
        } else {
          const toast = {
            type: 'error',
            title: 'Error',
            body: 'Something went wrong, please try again.',
            showCloseButton: true
          };
        }
      });
    } catch (error) {
      console.log(error);
      const toast = {
        type: 'error',
        title: 'Error',
        body: 'Something went wrong, please try again.',
        showCloseButton: true
      };
    }
  }

  
  public latitude:any;
  public longitude:any;
  public finalObj:any;
  public map:any;
  public disableAllFields:any;
  initMap() {
    try {

      var container = L.DomUtil.get('map');
      if (container != null) {
        container._leaflet_id = null;
      }
      let that = this;
      this.latitude = 23.6441;
      this.longitude = 85.3096;
      // var corner1 = L.latLng(24.013207, 83.276770), //west
      // corner2 = L.latLng(24.856621, 87.777565), //east
      // corner3 = L.latLng(21.85, 85.876770), //south
      // corner4 = L.latLng(25.54852, 87.828217) //north
      // let view = (data?.[0]?.lat && data?.[0]?.long) ? [data[0].lat,data[0].long] : [21.534,78.1212];
      this.map = L.map('map').setView([this.latitude, this.longitude], 7);
      L.tileLayer.wms(Config.CONSTANTS.LEAFLETTILES, {
        LAYERS: 'vehitrack',
        SERVICE: 'WMS',
        VERSION: '1.1.1',
        REQUEST: 'GetMap',
        STYLES: '',
        FORMAT: 'image/png',
        minZoom: 7,
        // maxZoom: 15
        // bounds:L.latLngBounds(corner1, corner2,corner3,corner4)
      }).addTo(this.map);
      L.geoJson(this.geoJsonObject, {color: 'grey'}).addTo(this.map);
      // L.latLngBounds(corner1, corner2,corner3,corner4)
      // L.tileLayer.wms(Config.CONSTANTS.LEAFLETTILES, {
      //   LAYERS: 'nuis:india_transport',
      //   SERVICE: 'WMS',
      //   VERSION: '1.1.1',
      //   REQUEST: 'GetMap',
      //   STYLES: '',
      //   FORMAT: 'image/png'
      // }).addTo(this.map);

      var coloring={
        1: "#c00000",
        2: "#f4b081",
        3: "#ffd865",
        4: "#a8d08c",
        5: "#538135"
      }
      var imagesStar={
        1: 'assets/icons/stars/1.png',
        2: 'assets/icons/stars/2.png',
        3: 'assets/icons/stars/3.png',
        4: 'assets/icons/stars/4.png',
        5: 'assets/icons/stars/5.png'
      }
      this.tableRecords.forEach(ele =>{
       
        this.icon = {
          icon: L.divIcon({
            className: 'custom-div-icon map_markers ' + 'station_status',
            // html: html
            html: "<div class='fa fa-map-marker fa-2x' style='color:"+coloring[parseInt(ele.rating)]+";'></div></br>",
            // html: "<img src='assets/images/icons/caaqms_delay.png' style='height:20px;' alt=''/>",
            // iconSize: [30, 30],
            // iconAnchor: [30, 30]
          })
        }

        if (ele.latitude && ele.longitude){
          const marker = L.marker([parseFloat(ele.latitude), parseFloat(ele.longitude)], this.icon).addTo(this.map).bindPopup(ele.industryName + '</br>' + "<img style='width:6rem' src=" + imagesStar[parseInt(ele.rating)] + ">");
        }
      })

      setTimeout(() => {
        this.map.invalidateSize(true);
      }, 300);
    } catch (error) {
      console.error(error);
    }
  }
  public icon:any;

  public indArray: any = [];
  public newListFormat: any;
  getIndustryFromFilters() {
    let input = {};
    input['fetchType'] = 'fetchAll';
    input['pageType'] = 'map_view';
    try {
      this._http.post(Config.API.GET_FILTERED_INDUSTRIES, input).subscribe(result => {
        if (result && result.status && result['data']['industries']) {
          this.copyIndustryList = result.data.industries.sort((a, b) => a.label < b.label ? -1 : 1);
          // this.sortBy(0);
          for (let i = 0; i < this.copyIndustryList.length; i++) {

            const element = this.copyIndustryList[i];

            if (!(element.coordinates && Array.isArray(element.coordinates) && element.coordinates[0] && element.coordinates[1])) {
              element.coordinates = [23.5102, 85.5799]
            }
            element['options'] = {
              color: 'white',
              fontWeight: 'bold',
              text: element['label'][0],
            };

            let icon = {
              url: '',
              scaledSize: {
                width: 25,
                height: 25,
              }
            };

            switch (element['rating']) {
              case '0':
                icon.url = 'assets/images/marker_0.png'
                element['star_img'] = 'assets/images/0Star.PNG';
                element['icon'] = icon;
                element['color'] = '#AF0808';
                break;
              case '1':
                icon.url = 'assets/images/marker_1.png'
                element['star_img'] = 'assets/icons/stars/1.png';
                element['icon'] = icon;
                element['color'] = '#FF0000';
                break;
              case '2':
                icon.url = 'assets/images/marker_2.png'
                element['star_img'] = 'assets/icons/stars/2.png';
                element['icon'] = icon;
                element['color'] = '#FF9A00';
                break;
              case '3':
                icon.url = 'assets/images/marker_3.png'
                element['star_img'] = 'assets/icons/stars/3.png';
                element['icon'] = icon;
                element['color'] = '#F0EA00';
                break;
              case '4':
                icon.url = 'assets/images/marker_4.png'
                element['star_img'] = 'assets/icons/stars/4.png';
                element['icon'] = icon;
                element['color'] = '#92D050';
                break;
              case '5':
                icon.url = 'assets/images/marker_5.png'
                element['star_img'] = 'assets/icons/stars/5.png';
                element['icon'] = icon;
                element['color'] = '#0fa339';
                break;

              default:
                icon.url = 'assets/images/marker_0.png'
                element['star_img'] = 'assets/images/0Star.PNG';
                element['icon'] = icon;
                element['color'] = '#b83030';
                // icon.url = 'assets/images/marker_1.png'
                // element['star_img'] = 'assets/icons/stars/1.png';
                // element['icon'] = icon;
                // element['color'] = '#FF0000';
                break;
            }
          }
          this.tableData = JSON.parse(JSON.stringify(this.copyIndustryList)).sort((a, b) => a.label < b.label ? -1 : 1);
          for (let index = 0; index < this.tableData.length; index++) {
            this.indArray[index] = this.tableData[index].label;
          }
          // console.log(this.tableData)
          this.newListFormat = [];
          var loopIndex = 0;
          let newArray = [];
          for (let index = 0; index < this.tableData.length; index++) {
            newArray.push(this.tableData[index])
            loopIndex = loopIndex + 1;
            if (loopIndex === 3) {
              this.newListFormat.push(newArray)
              newArray = []
              loopIndex = 0;
            }

            if ((index === (this.tableData.length - 1) && loopIndex < 3)) {
              this.newListFormat.push(newArray)
              newArray = []
              loopIndex = 0;
            }


          }
          // console.log(this.newListFormat)
        } else {
          const toast = {
            type: 'error',
            title: 'Error',
            body: 'Something went wrong, please try again.',
            showCloseButton: true
          };
          // this.toasterService.pop(toast);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  clickedMarker(i) {
    try {
      this.industryView['slide'] = true;
      this.industryView['industry'] = this.industryList[i];
      this.drawChartsSubject.next(this.industryView['industry']['siteId'])
    } catch (error) {
      console.log(error);
    }
  }

  onMouseOver(infoWindow, $event: MouseEvent) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }
  navigateToDetails(detail) {
    // this.router.navigate(['']);
  }

  public recordsToShow = 10;
  public recordsDisplay = true;
  public recordsList = [
    { key: -1, label: 'All' },
    { key: 10, label: '10' },
    { key: 20, label: '20' },
    { key: 50, label: '50' },
    { key: 100, label: '100' }, { key: 200, label: '200' }]


  public parameters_list = [
    {
      key: 'pm', label: 'PM'
    }
  ]
  reform() {
    try {
      this.tableData = undefined;
      this.recordsDisplay = false;
      setTimeout(() => {
        this.tableData = this.filterIndustries(this.copyIndustryList);
        this.recordsDisplay = true;
      }, 500);
    } catch (error) {
      console.log(error);
    }
  }
  filterIndustries(array) {
    try {

      const filterKeys = ['categories', 'star', 'districts'];
      let temp = JSON.parse(JSON.stringify(this.copyIndustryList));
      let temp2 = [];
      const map = {
        categories: 'category',
        star: 'rating',
        districts: 'district'
      }
      filterKeys.forEach(filter => {
        if (this.selectedFilters[filter]) {
          temp.forEach(row => {
            if (row[map[filter]] && this.selectedFilters[filter]) {
              if (row[map[filter]].toLowerCase() === this.selectedFilters[filter].toLowerCase()) {
                temp2.push(row);
              }
            }
          });
        } else {
          temp2 = JSON.parse(JSON.stringify(temp));
        }
        temp = JSON.parse(JSON.stringify(temp2));
        temp2 = [];
      });
      return temp;
    } catch (error) {
      console.log(error);
    }
  }
  convertArray(array, convert) {
    try {
      let bArray = [];
      for (let i = 0; i < array.length; i++) {
        let element = array[i];
        bArray.push(element[convert]);
      }
      return bArray;
    } catch (error) {
      console.log(error);
    }
  }
  styleFunc(feature) {
    return ({
      clickable: false,
      fillColor: feature.getProperty('color'),
      strokeColor: '#779690',
      strokeWeight: 0.8
    });
  }
  getGeoJsonObject() {
    this.geoJsonObject = {}
    try {
      this._http
        .get(Config.JSON.GET_JHARKHAND_GEO)
        .subscribe(result => {
          if (result) {
            this.geoJsonObject = result;
            this.initMap();
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  setFilterData() {
    // try {
    //   this._http.get(Config.API.FILTER_DATA)
    //     .subscribe(responseJson => {
    //       if (responseJson.status) {
    //         this.filterData = (responseJson.data.filterDrops);
    //         // this.filterData.push({label:'PM Value',key:'pmValue',subFilterDrops:[
    //         //   // {key:-1,label:'All'},
    //         //   {key:1,label:'1'},
    //         //   {key:2,label:'2'},
    //         //   {key:3,label:'3'},
    //         //   {key:4,label:'4'},
    //         //   {key:200,label:'5'}],
    //         //   type:'checkbox'})
    //         this.filterData.forEach((element, ind) => {
    //           if (element.key !== 'industriesPerPage') {
    //             // element.subFilterDrops=[]
    //             element.subFilterDrops.unshift({ key: '', label: 'All' })
    //           }
    //         });
    //       } else {
    //         this.filterData = undefined;
    //       }
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  }
  dropdownData(){
    try {
      this._http.get(Config.API.FILTER_DATA).subscribe(response => {
        if(response.status){
          this.dropdownsData=response.data
        }
        else{
          this.toaster.open('error', response.message, response.status)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }


  public displayTypes = [
    {
      key: 'map',
      label: 'MAP'
    },
    {
      key: 'table',
      label: 'TABLE'
    }
  ]

  public displayType = 'map';
  action(row) {
    this.router.navigate(['industry-view', btoa(JSON.stringify(row)), this.pageLanguage]);
    // console.log(btoa(JSON.stringify(row)))
    // this.selectedIndustry = row;
    // this.getSiteDetails(row);

    var table = $('#example').DataTable();

    //   $('#_10').on( 'change', function () {
    //     table.page.len( 10 ).draw();
    // } );
  }

  onFilter() {
    this.filter = true;
  }
  onSearch() {
    this.filter = false;
  }
  getMonthAndYear() {
    const months = [
      {
        key: 1,
        value: "January"
      },
      {
        key: 2,
        value: "February"
      },
      {
        key: 3,
        value: "March"
      },
      {
        key: 4,
        value: "April"
      },
      {
        key: 5,
        value: "May"
      },
      {
        key: 6,
        value: "June"
      },
      {
        key: 7,
        value: "July"
      },
      {
        key: 8,
        value: "August"
      },
      {
        key: 9,
        value: "September"
      },
      {
        key: 10,
        value: "October"
      },
      {
        key: 11,
        value: "November"
      },
      {
        key: 12,
        value: "December"
      }
    ]
    let i;

    let baseYear = 2022
    let currentYear = new Date().getFullYear()
    let currentMonth = new Date().getMonth()
    if (baseYear === currentYear) {
      for (i = baseYear; i <= baseYear; i++) {
        this.monthYear2[i] = [currentMonth]
        if (i === currentYear) {
          this.monthYear2[i] = []
          for (let j = 7; j < currentMonth; j++) {
            this.monthYear2[i].push(months[j])
          }
        } else {
          this.monthYear2[i] = months
        }
      }
      this.year = Object.keys(this.monthYear2)
    } else {
      for (i = baseYear; i <= currentYear; i++) {
        this.monthYear2[i] = [currentMonth]
        if (i === currentYear) {
          this.monthYear2[i] = []
          for (let j = 0; j < currentMonth; j++) {
            this.monthYear2[i].push(months[j])
          }
        } else {
          this.monthYear2[i] = months
        }
      }
      this.year = Object.keys(this.monthYear2)
    }
  }

  triggerReport() {
    // try {
    //   if (this.reportDetails['label'] && this.reportDetails['selectedYear'] && this.reportDetails['selectedMonth']) {
    //     this.toaster.open('success', 'Success', 'Done')
    //     if (this.reportDetails['viewType']) {
    //       if (this.reportDetails['viewType'] === 'lineGraph') {
    //         this.dataViewActive = 1;
    //         setTimeout(() => {
    //           this.graph()
    //         }, 1000);
    //       }
    //       else if (this.reportDetails['viewType'] === 'table') {
    //         this.dataViewActive = 2;
    //       }
    //     }
    //   } else {
    //     this.toaster.open('warning', 'Warning', 'Please select all the fields to proceed')
    //   }
    // }
    // catch (error) {
    //   this.toaster.open('error', 'Error', 'Internal Code Error')
    // }
    try {
      const payload=this.reportDetails
    } catch (error) {
      console.log(error)
    }
  }
  filterReports(){
    this.filterDetails.selectedIndustry=null
    this.filterDetails.selectedDistrict=null
    this.filterDetails.selectedCategory=null
    this.filterDetails.selectedRating=null
    this.filterDetails.selectedParameter=null
    this.selectedIndustry()
  }

  graph() {
    const myChart = echarts.init(document.getElementById('dataGraph'));

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      toolbox: {
        feature: {
          dataView: { show: true, title: 'Data', readOnly: false },
          magicType: { show: true, type: ['line', 'bar'] },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      legend: {
        data: ['Star Rating', 'PM Average', 'Data Availability']
      },
      xAxis: [
        {
          type: 'category',
          data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'PM Average',
          min: 0,
          max: 50,
          interval: 5,
          axisLabel: {
            formatter: '{value}'
          }
        },
        {
          type: 'value',
          name: 'Data Availability',
          min: 0,
          max: 5,
          interval: 2,
          axisLabel: {
            formatter: '{value}'
          }
        }
      ],
      series: [
        {
          name: 'Star Rating',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value as number + '';
            }
          },
          data: [10, 13, 11, 14, 9, 15, 21, 32, 26, 34, 40, 30]
        },
        {
          name: 'PM Average',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value as number + '';
            }
          },
          data: [10, 13, 11, 14, 9, 15, 21, 32, 26, 34, 40, 30]
        },
        {
          name: 'Data Availability',
          type: 'line',
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value) {
              return value as number + '';
            }
          },
          data: [1.2, 2.2, 2.3, 2.5, 2.3, 2.2, 3.3, 3.4, 3.0, 4.5, 5.0, 4.2]
        }
      ]
    }
    myChart.setOption(option);
  }
  selectedIndustry(){
    this.tableRecords=undefined;
    try {
      const payload=this.filterDetails;
      this._http.post(Config.API.SELECTED_INDUSTRY,payload).subscribe(response =>{
        if(response.status){
          this.tableRecords=response.data;
          this.getGeoJsonObject();
        }
        else{
          this.toaster.open('error', response.message, response.status)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
}