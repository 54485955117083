import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from 'src/app/config/config';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AlertService } from '../../shared/alert.service';
import { Subject } from 'rxjs';
// import { Toast, ToasterConfig, ToasterService } from 'angular2-toaster';


@Component({
  selector: 'kl-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('slickModal') slickModal;
  industryList = [];
  public tableData = null;
  //background-image:
  public dtOptions = {
    pagingType: 'full_numbers',
    language: {
      emptyTable: "No 5 star rated industries found"
    }
  };

  private drawChartsSubject: Subject<any> = new Subject<any>();
  public headerContent = [{
    "key": "label",
    "label": "Indusry"
  }, {
    "key": "district",
    "label": "District"
  }, {
    "key": "category",
    "label": "Category"
  }];
  public filterData: any = null;
  public industryView = {
    industry: null,
    slide: false,
    filterResultLoad: true,
  };
  pageLabels: any;
  JharkhandMap = {
    zoom: 7.5,
    lat: 23.5102,
    lng: 85.5799
  };
  copyIndustryList: any;
  geoJsonObject: {};
  public tabs = [{
    label: "About the program",
    img: 'assets/images/tab-1.jpg'
  }, {
    label: "Explore the Rating",
    img: 'assets/images/tab-2.jpg'
  }, {
    label: "About JSPCB",
    img: 'assets/images/tab-5.jpg'
  }];
  // public config1: ToasterConfig = new ToasterConfig({
  //   positionClass: 'toast-top-right'
  // });
  constructor(
    private router: Router,
    public _http: HttpLayerService,
    public toaster: AlertService,
    /* private toasterService: ToasterService */) { }
  public slideConfig = { "slidesToShow": 4, "slidesToScroll": 1, "infinite": true };

  ngOnInit() {
    this.getIndustryFromFilters();
    this.getGeoJsonObject();


    if (window.innerWidth <= 768) {
      this.slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, "infinite": true };
    }
    this.slider_1.forEach(data => {
      if (data.stars) {
        data['stars_fill'] = Array(data.stars).fill(0).map((x, i) => i);
        data['stars_empty'] = Array(5 - data.stars).fill(0).map((x, i) => i);
      }
    })

    this.slider_3.forEach(data => {
      if (data.stars) {
        data['stars_fill'] = Array(data.stars).fill(0).map((x, i) => i);
        data['stars_empty'] = Array(5 - data.stars).fill(0).map((x, i) => i);
      }
    })

    // setTimeout(() => {
    //   this.randomcall(0);
    // }, 3000);

  }

  randomcall(value) {


    this.slickModal.slickGoTo(value);
    // document.getElementById('trggerbtn').click();
    setTimeout(() => {
      this.randomcall(value < this.slider_3.length ? value + 1 : 0);
    }, 2000);

  }

  styleFunc(feature) {
    return ({
      clickable: false,
      fillColor: feature.getProperty('color'),
      strokeColor: '#779690',
      strokeWeight: 0.8
    });
  }
  redirect() {
    window.open("http://jsac.jharkhand.gov.in/pollution/");
  }

  navigate(route) {
    try {
      this.router.navigate([route, 'en']);
    } catch (error) {
      console.log(error);
    }
  }

  public least_polluted = [];
  public most_polluted = [];
  public air_quality = [];
  getIndustryFromFilters() {
    let input = {};
    // input['fetchType'] = 'fetchAll';
    // input['pageType'] = 'map_view';
    input['fetchType'] = 'fetchAll';
    input['user'] = 'jspcb_admin';
    try {
      this._http.post(Config.API.GET_FILTERED_INDUSTRIES, input).subscribe(result => {
        if (result && result.status && result['data']['industries']) {
          this.copyIndustryList = result.data.industries;

          if (result.data.least_polluted) {
            // this.least_polluted = result.data.least_polluted;
            this.least_polluted = result.data.least_polluted.sort((a, b) => a.label < b.label ? -1 : 1);
            this.least_polluted.forEach(data => {
              if (data.stars || data.stars === 0) {
                data['stars_fill'] = Array(data.stars).fill(0).map((x, i) => i);
                data['stars_empty'] = Array(5 - data.stars).fill(0).map((x, i) => i);
              }
            })
          }
          if (result.data.most_polluted) {
            // this.most_polluted = result.data.most_polluted;
            this.most_polluted = result.data.most_polluted.sort((a, b) => a.label < b.label ? -1 : 1);
            this.most_polluted.forEach(data => {
              if (data.stars || data.stars === 0) {
                data['stars_fill'] = Array(data.stars).fill(0).map((x, i) => i);
                data['stars_empty'] = Array(5 - data.stars).fill(0).map((x, i) => i);
              }
            })
          }
          if (result.data.air_quality) {
            this.air_quality = result.data.air_quality;
            this.air_quality.forEach(data => {
              if (data.stars || data.stars === 0) {
                data['stars_fill'] = Array(data.stars).fill(0).map((x, i) => i);
                data['stars_empty'] = Array(5 - data.stars).fill(0).map((x, i) => i);
              }
            })
          }


          // this.sortBy(0);
          for (let i = 0; i < this.copyIndustryList.length; i++) {

            const element = this.copyIndustryList[i];

            /*if (!(element.coordinates && Array.isArray(element.coordinates) && element.coordinates[0] && element.coordinates[1])) {
              element.coordinates = [23.5102, 85.5799]
            }*/
            element['options'] = {
              color: 'white',
              fontWeight: 'bold',
              text: element['label'][0],
            };
            let icon = {
              url: '',
              scaledSize: {
                width: 25,
                height: 25,
              }
            };
            switch (element['rating']) {
              case '0':
                icon.url = 'assets/images/marker_0.png'
                element['icon'] = icon;
                break;
              case '1':
                icon.url = 'assets/images/marker_1.png'
                element['icon'] = icon;
                break;
              case '2':
                icon.url = 'assets/images/marker_2.png'
                element['icon'] = icon;
                break;
              case '3':
                icon.url = 'assets/images/marker_3.png'
                element['icon'] = icon;
                break;
              case '4':
                icon.url = 'assets/images/marker_4.png'
                element['icon'] = icon;
                break;
              case '5':
                icon.url = 'assets/images/marker_5.png'
                element['icon'] = icon;
                break;
              default:
                icon.url = 'assets/images/marker_0.png'
                element['icon'] = icon;
                break;
            }
          }
          this.tableData = JSON.parse(JSON.stringify(this.copyIndustryList));
        } else {
          const toast = {
            type: 'error',
            title: 'Error',
            body: 'Something went wrong, please try again.',
            showCloseButton: true
          };
          // this.toasterService.pop(toast);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  clickedMarker(i) {
    try {
      this.industryView['slide'] = true;
      this.industryView['industry'] = this.industryList[i];
      this.drawChartsSubject.next(this.industryView['industry']['siteId'])
    } catch (error) {
      console.log(error);
    }
  }

  onMouseOver(infoWindow, $event: MouseEvent) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }
  getGeoJsonObject() {
    this.geoJsonObject = {}
    try {
      this._http
        .get(Config.JSON.GET_JHARKHAND_GEO)
        .subscribe(result => {
          if (result) {
            this.geoJsonObject = result;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }



  public slider_1 = [
    {
      "icon": "fa fa-flask",
      "industry_name": "Vinati Organics",
      "industry_area": "Raigad",
      "industry_type": "Chemicals",
      "stars": 3,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012
    },
    {
      "icon": "fa fa-plug",
      "industry_name": "Tessitura Monti India Private Limited",
      "industry_area": "Kolhapur",
      "industry_type": "Power",
      "stars": 5,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012
    },
    {
      "icon": "fa fa-cogs",
      "industry_name": "Ultra Tech Cement",
      "industry_area": "Ratnagiri",
      "industry_type": "CEMENT",
      "stars": 5,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012
    },
    {
      "icon": "fa fa-industry",
      "industry_name": "Welspun Maxstell (JSW Steel)",
      "industry_area": "Raigad",
      "industry_type": "CEMENT",
      "stars": 3,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012
    }
  ]


  public slider_3 = [
    {
      "icon": "fa fa-flask",
      "industry_name": "Vinati Organics 1",
      "industry_area": "Raigad",
      "industry_type": "Chemicals",
      "stars": 3,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012,
      "pm10": 10,
      "message": "No data in 2022"
    },
    {
      "icon": "fa fa-flask",
      "industry_name": "Vinati Organics 2",
      "industry_area": "Raigad",
      "industry_type": "Chemicals",
      "stars": 3,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012,
      "pm10": 10,
      "message": "No data in 2022"
    },
    {
      "icon": "fa fa-flask",
      "industry_name": "Vinati Organics 3",
      "industry_area": "Raigad",
      "industry_type": "Chemicals",
      "stars": 3,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012,
      "pm10": 10,
      "message": "No data in 2022"
    },
    {
      "icon": "fa fa-flask",
      "industry_name": "Vinati Organics 4",
      "industry_area": "Raigad",
      "industry_type": "Chemicals",
      "stars": 3,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012,
      "pm10": 10,
      "message": "No data in 2022"
    },
    {
      "icon": "fa fa-flask",
      "industry_name": "Vinati Organics 5",
      "industry_area": "Raigad",
      "industry_type": "Chemicals",
      "stars": 3,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012,
      "pm10": 10,
      "message": "No data in 2022"
    },
    {
      "icon": "fa fa-flask",
      "industry_name": "Vinati Organics 6",
      "industry_area": "Raigad",
      "industry_type": "Chemicals",
      "stars": 3,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012,
      "pm10": 10,
      "message": "No data in 2022"
    },
    {
      "icon": "fa fa-flask",
      "industry_name": "Vinati Organics 7",
      "industry_area": "Raigad",
      "industry_type": "Chemicals",
      "stars": 3,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012,
      "pm10": 10,
      "message": "No data in 2022"
    }
  ]


  addSlide() {
    this.slider_3.push({
      "icon": "fa fa-flask",
      "industry_name": "Vinati Organics 1",
      "industry_area": "Raigad",
      "industry_type": "Chemicals",
      "stars": 3,
      "email": "mohammadmuzmil.shaik@knowledgelens.com",
      "phone": 83175132012,
      "pm10": 10,
      "message": "No data in 2022"
    })
  }

  removeSlide() {
    this.slider_3.length = this.slider_3.length - 1;
  }

  slickInit(e) {
    // console.log('slick initialized');
  }

  breakpoint(e) {
    // console.log('breakpoint');
  }

  afterChange(e) {
    // console.log('afterChange');
  }

  beforeChange(e) {
    // console.log('beforeChange');
  }


  fetchIndustriesList() {
    this._http.post(Config.API.GET_FILTERED_INDUSTRIES, { "fetchType": "fetchAll", "pageType": "map_view" }).subscribe(result => {

    })
  }

}
