import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/alert-service.service';
import { Config } from 'src/app/config/config';
import { HttpLayerService } from 'src/app/services/http-layer.service';

@Component({
  selector: 'kl-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit {

  public faqData:any;
  constructor(public httpService: HttpLayerService, public toaster: AlertService) { }

  ngOnInit() {
    this.getFAQData();
  }

  getFAQData(){
    try {
      this.httpService.get(Config.JSON.FAQ_DATA).subscribe((result) => {
        if (result && result.status && result.status) {
          this.faqData = result.data;
          // this.reportTable = true;
        } else {
          this.toaster.open('warning', 'Failed', result.message || 'Error while fetching data')
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

}
