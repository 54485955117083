import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'kl-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
public searchdata: any;
public allData: any;
public searchResult:any;
public filteredData:any;
public flag = true;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private _http: HttpLayerService) {
     }

  ngOnInit() {
    this.searchdata = this._http.searchElement;
    this.getAllData();
  }
  getAllData(){
    try{
          this._http.get(Config.JSON.allData).subscribe(result =>{
              if(result && result['status']){
                this.allData = result['data'];
              }
              if(this.searchdata){
                this.showResult();
              }
    });
  } catch (error) {
    console.log(error);
  }
  }
  showResult(){
    const searchItem = []
    let ind
    let str = '';
    this.allData.forEach(element => {
      var re = new RegExp(this.searchdata, 'i');
      if(element['value'].match(re)){
        ind = element['value'].match(re).index;
        while(element['value'].match(re)['input'][ind] != '.'){
            str += element['value'].match(re)['input'][ind];
            ind += 1;
        }
        element['filteredData'] = str;
        searchItem.push(element);
        this.filteredData = str;
        str = '';
      }
    });
    this.searchResult = JSON.parse(JSON.stringify(searchItem));
    if(this.searchResult && this.searchResult.length>0)
        this.flag = true;
    else
        this.flag=false;
  }
}
