import { Component, OnInit } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from "src/app/config/config";
import { AlertService } from '../../shared/alert.service';

@Component({
  selector: 'kl-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactFields: any;
  contact = {
    name: '',
    email: '',
    // mobile: '',
    comment: '',
  };
  isMobile: boolean;
  constructor(
    private _http: HttpLayerService,
    public toaster: AlertService,
  ) { }

  ngOnInit() {
    this.getTranslations();
    this.checkForResponsiveness();
  }
  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe((result) => {
          if (result['aboutStarProgramComponent']) {
            this.contactFields = result['aboutStarProgramComponent']['contact'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  checkForResponsiveness() {
    if (window.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
  isFeedbackValid() {
    let valid: boolean = true;
    if (this.contact.name.trim() === '' || this.contact.email.trim() === '' || this.contact.comment.trim() === '') {
      valid = false;
    }
    return !valid;
  }
  submitFeedback() {
    let input = this.contact;
    JSON.stringify(input);
    try {
      this._http
        .post(Config.API.SUBMIT_FEEDBACK, input)
        .subscribe(result => {
          if (result) {
            this.toaster.open('success', '', 'Message submitted');
            this.contact = {
              name: '',
              email: '',
              // mobile: '',
              comment: '',
            };
          }
        });
    } catch (error) {
      console.log(error);
      this.toaster.open('error', '', 'Something went wrong, please try again.');
    }
  }
}
