import { Component, OnInit } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { AlertService } from 'src/app/shared/alert.service';
import { Router } from '@angular/router';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'kl-about-jspcb',
  templateUrl: './about-jspcb.component.html',
  styleUrls: ['./about-jspcb.component.scss']
})
export class AboutJspcbComponent implements OnInit {
  aboutJspcb: any;

  constructor(
    public _http: HttpLayerService,
    public toaster: AlertService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getTranslations();
  }

  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['aboutJspcbComponent']) {
            this.aboutJspcb = result['aboutJspcbComponent'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

}
