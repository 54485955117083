import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Config } from 'src/app/config/config';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { AlertService } from 'src/app/shared/alert.service';

import * as echarts from 'echarts';
import * as c3 from 'c3';
import ECharts = echarts.ECharts;


@Component({
  selector: 'kl-industry-view',
  templateUrl: './industry-view.component.html',
  styleUrls: ['./industry-view.component.scss']
})
export class IndustryViewComponent implements OnInit {

  public sitePayload:any;
  public pageLanguage:any;
  public pageType:any;
  public siteDetails:any;
  public selectedIndustry:any;

  public displayContent:boolean=false;

  constructor(private route: ActivatedRoute, public _http: HttpLayerService, public toaster: AlertService) {
    this.route.params.subscribe(params => {

      this.sitePayload = params['info'] ? JSON.parse(atob(params['info'])) : undefined;
      
    }
    )
  }

  ngOnInit() {

    if(this.sitePayload){
      this.getSiteDetails(this.sitePayload);
    }

    
  }


  getSiteDetails(payLoad) {
    this.pageType = 'siteDetails';
    this.selectedIndustry=this.sitePayload
    this.siteDetails = {};
    try {
      // console.log(payLoad)
      this._http.post(Config.API.jspcbIndustryData, payLoad).subscribe(result => {
        if (result) {
          this.siteDetails = result;
          this.populateHeatmap();
          // setTimeout(() => {
          //   this.displayContent=true
          // }, 2000);
        } else {
          this.toaster.open('error', '', 'Something went wrong, please try again.');
        }
      });
    } catch (error) {
      console.log(error);
      this.toaster.open('error', '', 'Something went wrong, please try again.');
    }
  }



  populateHeatmap() {
    try {
      let counter = 0;
      let data = [];
      let paramList = [];
      let stackList = [];
      let parameters: any = {};
      this.siteDetails.stackData.forEach(function (station, stationIndex) {

        stackList.push(station.stackName);
        let params = (Object.keys(station.dataAvailability));
        params.forEach(function (parameter) {
          if (parameters[parameter] || parameters[parameter] === 0) {
            data.push([stationIndex, parameters[parameter], station.dataAvailability[parameter]]);
          } else {
            paramList.push(parameter);
            parameters[parameter] = counter;
            counter++;
            data.push([stationIndex, parameters[parameter], station.dataAvailability[parameter]]);
          }
        });
      });
      setTimeout(() => {
        this.createCharts();
      }, 1000);
      const myChart = echarts.init(document.getElementById('dataAvailable'));

      const option = {
        tooltip: {
          position: 'top'
        },
        grid: {
          height: '50%',
          top: '10%'
        },
        xAxis: {
          type: 'category',
          data: stackList,
          splitArea: {
            show: true
          }
        },
        yAxis: {
          type: 'category',
          data: paramList,
          splitArea: {
            show: true
          }
        },
        visualMap: {
          min: 0,
          max: 100,
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '15%'
        },
        series: [{
          name: 'Punch Card',
          type: 'heatmap',
          data: data,
          label: {
            show: true
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      };
      myChart.setOption(option);
    } catch (e) {
      console.error(e);
    }
  }


  createCharts() {
    try {
      let that = this;
      this.siteDetails.stackData.forEach(function (item, ind) {
        let data = item.allParamsGraph;
        const chartData: any = [];
        let maxYval = 0;
        data.forEach(key => {
          let temp = Math.max(...key.sparkList);
          maxYval = Math.max(temp, maxYval);
          if (key.sparkListTime) {
            chartData[0] = key.sparkListTime;
            chartData[0].unshift('x');
          }
          if (key.sparkList) {
            const temp = key.sparkList;
            temp.unshift(key.parameterName);
            chartData.push(temp);
          }
        });
        if (chartData.length > 0) {
          that.drawChart(chartData, maxYval, ind);
        }
      });
    } catch (e) {
      console.error(e);
    }

  }


  drawChart(data, maxYval, ind) {
    try {
      const ticksConfX = { format: '%d/%m %H:%M', count: 5 };
      maxYval += 5;
      c3.generate({
        size: {
          width: 1020
        },
        bindto: '#chart_' + ind,
        data: {
          x: 'x',
          xFormat: '%Y-%m-%d %H:%M:%S',
          columns: data,
        },
        point: {
          r: 4,
        },
        padding: {
          top: 20,
          left: 60,
          right: 20,
          bottom: 20
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: ticksConfX,
            label: {
              text: 'Date Time',
              position: 'outer-center'
            },
          },
          y: {
            // min: 0,
            padding: { top: 0, bottom: 0 },
            max: maxYval,
            min: 0
          }
        },

        grid: {
          y: {
            show: true
          },
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
}
