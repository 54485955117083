import { Component, OnInit } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from 'src/app/config/config';
import { AlertService } from '../../shared/alert.service';
import { Subject } from 'rxjs';
// import { Toast, ToasterConfig, ToasterService } from 'angular2-toaster';

@Component({
  selector: 'kl-city-rating',
  templateUrl: './city-rating.component.html',
  styleUrls: ['./city-rating.component.scss']
})
export class CityRatingComponent implements OnInit {
  selectedFilters: Object = {
    star: '',
    paramterValue:'',
    parameter:'pm'
  };
  industryList = [];
  public tableData = null;
  public dtOptions = {
    pagingType: 'full_numbers',
    searching: false,
    responsive: true,
    language: {
      emptyTable: "Data is not available for the selected filters"
    },
    scrollY: '65vh',
    scrollCollapse: true,
  };
  public headerContent = [
    { "label": "City", "key": "city" },
    { "label": "Pollutant", "key": "parameterValue" }
  ];
  public filterData: any = [
    {
      "label": "Rating",
      "key": "star",
      "type": "checkbox",
      "subFilterDrops": [
        {
          "key": "5",
          "label": "Five rating"
        },
        {
          "key": "4",
          "label": "Four Rating"
        },
        {
          "key": "3",
          "label": "Three Rating"
        },
        {
          "key": "2",
          "label": "Two Rating"
        },
        {
          "key": "1",
          "label": "One Rating"
        },
        {
          "key": "",
          "label": "All"
        }
      ]
    },
    // {
    //   "label": "Paramter Value (PM)",
    //   "key": "parameterValue",
    //   "type": "checkbox",
    //   "subFilterDrops": [

    //   ]
    // }
  ];
  public recordsList = [
    { key: -1, label: 'All' },
    { key: 10, label: '10' },
    { key: 20, label: '20' },
    { key: 50, label: '50' },
    { key: 100, label: '100' }, { key: 200, label: '200' }]
  public recordsToShow = 10;
  public industryView = {
    industry: null,
    slide: false,
    filterResultLoad: true,
  };
  public showFilters: boolean;
  pageLabels: any;
  JharkhandMap = {
    zoom: 7.5,
    lat: 23.5102,
    lng: 85.5799
  };
  copyIndustryList: any;
  geoJsonObject: {};
  public parameters_list = [
    {
      key:'pm', label:'PM'
    }
  ]
  public pmvalues: any = []
  // public config1: ToasterConfig = new ToasterConfig({
  //   positionClass: 'toast-top-right'
  // });
  constructor(
    public _http: HttpLayerService,
    public toaster: AlertService,
    /* private toasterService: ToasterService */) { }

  ngOnInit() {
    if (screen.width < 450) {
      this.dtOptions.pagingType = 'simple';
    }
    this.getIndustryFromFilters();
    this.getGeoJsonObject();
  }
  getIndustryFromFilters() {
    let input = {};
    this.showFilters = false;
    try {
      this._http.post(Config.API.cityWiseStarRating, input).subscribe(result => {
        if (result.bodyContent) {
          this.copyIndustryList = result.bodyContent.sort((a, b) => a.city < b.city ? -1 : 1);
          // this.sortBy(0);

          const pmvalues = []
          for (let i = 0; i < this.copyIndustryList.length; i++) {

            const element = this.copyIndustryList[i];
            if (this.filterData && this.filterData[1]) {
              pmvalues.push(element['parameterValue'])
            }
            let icon = {
              url: '',
              scaledSize: {
                width: 25,
                height: 25,
              }
            };
            switch (element['starRating']) {
              case '0':
                icon.url = 'assets/images/marker_0.png'
                element['star_img'] = 'assets/images/0Star.PNG';
                element['building_img'] = 'assets/images/0Star_building.PNG';
                element['icon'] = icon;
                element['color'] = '#AF0808';
                break;
              case '1':
                icon.url = 'assets/images/marker_1.png'
                element['star_img'] = 'assets/images/1Star.PNG';
                element['building_img'] = 'assets/images/1Star_building.PNG';
                element['icon'] = icon;
                element['color'] = '#FF0000';
                break;
              case '2':
                icon.url = 'assets/images/marker_2.png'
                element['star_img'] = 'assets/images/2Star.PNG';
                element['building_img'] = 'assets/images/2Star_building.PNG';
                element['icon'] = icon;
                element['color'] = '#FF9A00';
                break;
              case '3':
                icon.url = 'assets/images/marker_3.png'
                element['star_img'] = 'assets/images/3Star.PNG';
                element['building_img'] = 'assets/images/3Star_building.PNG';
                element['icon'] = icon;
                element['color'] = '#F0EA00';
                break;
              case '4':
                icon.url = 'assets/images/marker_4.png'
                element['star_img'] = 'assets/images/4Star.PNG';
                element['building_img'] = 'assets/images/4Star_building.PNG';
                element['icon'] = icon;
                element['color'] = '#92D050';
                break;
              case '5':
                icon.url = 'assets/images/marker_5.png'
                element['star_img'] = 'assets/images/5Star.PNG';

                element['building_img'] = 'assets/images/5Star_building.PNG';
                element['icon'] = icon;
                element['color'] = '#0C7A2B';
                break;

              default:
                icon.url = 'assets/images/marker_0.png'
                element['star_img'] = 'assets/images/0Star.PNG';

                element['building_img'] = 'assets/images/0Star_building.PNG';
                element['icon'] = icon;
                element['color'] = '#AF0808';
                break;
            }
          }
          var myArr = [];
          var mySet = Array.from(new Set(pmvalues));
          myArr = [...mySet];
          myArr=myArr.sort();
          if(myArr.includes(0)){
            // console.log("includes")
          }
          if (this.filterData && this.filterData[1]) {
            myArr.forEach(element => {
              this.filterData[1].subFilterDrops.push({
                'key':element,
                'label':element
              })
            });
            this.filterData[1].subFilterDrops.unshift({key:'',label:'All'})
          }

          this.showFilters = true;
          this.tableData = JSON.parse(JSON.stringify(this.copyIndustryList));
        } else {
          const toast = {
            type: 'error',
            title: 'Error',
            body: 'Something went wrong, please try again.',
            showCloseButton: true
          };
          // this.toasterService.pop(toast);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  reform(type?) {
    try {
      this.tableData = undefined;
      setTimeout(() => {
        if(type){
          this.tableData = this.filterIndustries(type);
        } else {
          this.tableData = this.filterIndustries();
        }
      }, 500);
    } catch (error) {
      console.log(error);
    }
  }
  filterIndustries(type?) {
    try {

      const filterKeys = ['categories', 'star', 'districts', 'parameterValue'];
      let temp = JSON.parse(JSON.stringify(this.copyIndustryList));
      let temp2 = [];
      const map = {
        categories: 'category',
        star: 'starRating',
        districts: 'district',
        parameterValue: 'parameterValue'
      }

      if(type === "parameterValue"){
        filterKeys.forEach(filter => {
          if (this.selectedFilters[filter]) {
            temp.forEach(row => {
              if (row[map[filter]] && this.selectedFilters[filter]) {
                if (row[map[filter]] === this.selectedFilters[filter]) {
                  temp2.push(row);
                }
              }
            });
          } else {
            temp2 = JSON.parse(JSON.stringify(temp));
          }
          temp = JSON.parse(JSON.stringify(temp2));
          temp2 = [];
        });
      } else {
        filterKeys.forEach(filter => {
          if (this.selectedFilters[filter]) {
            temp.forEach(row => {
              if (row[map[filter]] && this.selectedFilters[filter]) {
                if (row[map[filter]].toLowerCase() === this.selectedFilters[filter].toLowerCase()) {
                  temp2.push(row);
                }
              }
            });
          } else {
            temp2 = JSON.parse(JSON.stringify(temp));
          }
          temp = JSON.parse(JSON.stringify(temp2));
          temp2 = [];
        });
      }
      return temp;
    } catch (error) {
      console.log(error);
    }
  }
  convertArray(array, convert) {
    try {
      let bArray = [];
      for (let i = 0; i < array.length; i++) {
        let element = array[i];
        bArray.push(element[convert]);
      }
      return bArray;
    } catch (error) {
      console.log(error);
    }
  }
  styleFunc(feature) {
    return ({
      clickable: false,
      fillColor: feature.getProperty('color'),
      strokeColor: '#779690',
      strokeWeight: 0.8
    });
  }
  getGeoJsonObject() {
    this.geoJsonObject = {}
    try {
      this._http
        .get(Config.JSON.GET_JHARKHAND_GEO)
        .subscribe(result => {
          if (result) {
            this.geoJsonObject = result;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
}
