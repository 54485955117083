import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/alert-service.service';
import { Config } from 'src/app/config/config';
import { HttpLayerService } from 'src/app/services/http-layer.service';

@Component({
  selector: 'kl-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  constructor(public _http: HttpLayerService,public toaster: AlertService) { }
  public industryList:any=[];
  public formData:any={};

  ngOnInit() {
  this.industry()
  }
  industry(){
    this.industryList=['ind1','ind2','ind3','ind4']
  }
  triggerReport() {
    try {
      const payload={
        payload:this.formData
      }
      this._http.post(Config.API.REGISTER_MAIL, payload).subscribe((response) => {
        if (response.status==='success') {
          this.toaster.open('success',response.message,'Success')
          this.formData.email=undefined
        }else{
          this.toaster.open('warning',response.message || 'Error while Signup','Failed')
          // this.formData.email=undefined
        }
      })
    } catch (error) {
      console.error(Error)
    }
  }
}
