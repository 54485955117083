import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { NavHeaderComponent } from './nav-header/header.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedSliderComponent } from './shared-slider/shared-slider.component';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PortalLoginComponent } from './portal-login/portal-login.component';
import { NewUserComponent } from './new-user/new-user.component';
import { SignupComponent } from './signup/signup.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [HeaderComponent,
    NavHeaderComponent, SharedSliderComponent, FooterComponent, PortalLoginComponent, ForgotPasswordComponent, NewUserComponent, SignupComponent],
  imports: [
    CommonModule,FormsModule,ReactiveFormsModule,NgSelectModule
  ],

  exports: [
    HeaderComponent,
    NavHeaderComponent,
    SharedSliderComponent,
    FooterComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {

}
