import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { ComponentsModule } from '../components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainPageComponent } from './main-page/main-page.component';
import { NewsUpdateComponent } from './news-update/news-update.component';
import { ExploreRatingsComponent } from './explore-ratings/explore-ratings.component';
import { MultiSearchCheckboxComponent } from './multi-search-checkbox/multi-search-checkbox.component';
import { MultiSearchRatingComponent } from './multi-search-rating/multi-search-rating.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { RatingModule } from 'ngx-bootstrap/rating';
import { AgmCoreModule } from '@agm/core';
import { AboutStarPgmComponent } from './about-star-pgm/about-star-pgm.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DataTablesModule } from 'angular-datatables';

import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxTwitterTimelineModule } from 'ngx-twitter-timeline';
import { ContactComponent } from './contact/contact.component';
import { TwitterComponent } from './twitter/twitter.component';
import { WhyStarRatingComponent } from './why-star-rating/why-star-rating.component';
import { WhyJarkhandComponent } from './why-jarkhand/why-jarkhand.component';
import { NewsUpdateViewComponent } from './news-update-view/news-update-view.component';
import { SearchPipe } from './multi-search-checkbox/search.pipe';
import { AboutJspcbComponent } from './about-jspcb/about-jspcb.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HomeComponent } from './home/home.component';
import { IndustryRatingComponent } from './industry-rating/industry-rating.component';
import { CityRatingComponent } from './city-rating/city-rating.component';
import { AmIAtRiskComponent } from './am-i-at-risk/am-i-at-risk.component';
import { AgmJsMarkerClustererModule, ClusterManager } from '@agm/js-marker-clusterer';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { IndustryViewComponent } from './industry-view/industry-view.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LoginPageComponent } from './login-page/login-page.component';
import { StarRatingMethodologyComponent } from './star-rating-methodology/star-rating-methodology.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
// // import { ToasterModule, ToasterService } from 'angular2-toaster';
import { FAQComponent } from './faq/faq.component';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';
// import { NgxEchartsModule } from 'ngx-echarts';
@NgModule({
  declarations: [
    PagesComponent,
    MainPageComponent,
    NewsUpdateComponent,
    ExploreRatingsComponent,
    MultiSearchCheckboxComponent,
    MultiSearchRatingComponent,
    AboutStarPgmComponent,
    ContactComponent,
    TwitterComponent,
    WhyStarRatingComponent,
    WhyJarkhandComponent,
    NewsUpdateViewComponent,
    SearchPipe,
    AboutJspcbComponent,
    DashboardComponent,
    HomeComponent,
    IndustryRatingComponent,
    CityRatingComponent,
    AmIAtRiskComponent,
    IndustryViewComponent,
    LoginPageComponent,
    StarRatingMethodologyComponent,
    SearchFilterComponent,
    FAQComponent,
    DashboardNewComponent,
  ],
  imports: [
    FilterPipeModule,
    CommonModule,
    PagesRoutingModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgxTwitterTimelineModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    RatingModule.forRoot(),
    ComponentsModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBZeF5BTfHVIsdC8w4VtInIzFYqW0ftF5k' }),
    AgmJsMarkerClustererModule,
    SlickCarouselModule,
    Ng2SearchPipeModule,
    // NgxEchartsModule
  ],
  providers: [ClusterManager],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule { }
