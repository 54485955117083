import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from 'src/app/config/config';
import { AlertService } from '../../shared/alert.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as echarts from 'echarts';
import * as c3 from 'c3';
import ECharts = echarts.ECharts;
import { buildRawList } from '@angular/flex-layout/extended/typings/style/style-transforms';

@Component({
  selector: 'kl-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  pageLabels: any;
  allPosts: any;
  private twitter: any;
  public selectedIndustry = null;
  public DATableHeader = [
    "hours",
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23"
  ];
  public lanId;
  public siteDetails: any = {};
  public pageType = 'all';
  tableData = {
    headerContent: [],
    bodyContent: [],
  };
  dtOptions = {
    pagingType: 'full_numbers',
    language: {
      emptyTable: "No 5 star rated industries found"
    }
  };

  userLogged: Boolean = false;
  isTableDataLoaded: boolean = false;
  public sitePayload:any;
  constructor(
    public _http: HttpLayerService,
    public _router: Router,
    private route: ActivatedRoute,
    public toaster: AlertService, private router: Router) {
    this.route.params.subscribe((params) => {
      this.lanId = params.id;
      this.sitePayload = params['info'] ? JSON.parse(atob(params['info'])) : undefined;
    });
  }
  ngOnInit() {
    this.pageLanguage = sessionStorage.getItem('language');
    if(this.sitePayload){
      this.action(this.sitePayload)
    }
    // this.getIndustryList();
  }
  getIndustryList() {
    try {
      this._http.post(Config.API.jspcbIndustryDetails, {}).subscribe(result => {
        if (result.status) {
          this.tableData = result;
          this.isTableDataLoaded = true;
        } else {
          this.toaster.open('error', '', 'Something went wrong, please try again.');
        }
      });
    } catch (error) {
      console.log(error);
      this.toaster.open('error', '', 'Something went wrong, please try again.');
    }
  }
  getSiteDetails(payLoad) {
    this.pageType = 'siteDetails';
    this.siteDetails = {};
    try {
      this._http.post(Config.API.jspcbIndustryData, payLoad).subscribe(result => {
        if (result) {
          this.siteDetails = result;
          this.populateHeatmap();
        } else {
          this.toaster.open('error', '', 'Something went wrong, please try again.');
        }
      });
    } catch (error) {
      console.log(error);
      this.toaster.open('error', '', 'Something went wrong, please try again.');
    }
  }
  createCharts() {
    try {
      let that = this;
      this.siteDetails.stackData.forEach(function (item, ind) {
        let data = item.allParamsGraph;
        const chartData: any = [];
        let maxYval = 0;
        data.forEach(key => {
          let temp = Math.max(...key.sparkList);
          maxYval = Math.max(temp, maxYval);
          if (key.sparkListTime) {
            chartData[0] = key.sparkListTime;
            chartData[0].unshift('x');
          }
          if (key.sparkList) {
            const temp = key.sparkList;
            temp.unshift(key.parameterName);
            chartData.push(temp);
          }
        });
        if (chartData.length > 0) {
          that.drawChart(chartData, maxYval, ind);
        }
      });
    } catch (e) {
      console.error(e);
    }

  }

  drawChart(data, maxYval, ind) {
    try {
      const ticksConfX = { format: '%d/%m %H:%M', count: 5 };
      maxYval += 5;
      c3.generate({
        size: {
          width: 1020
        },
        bindto: '#chart_' + ind,
        data: {
          x: 'x',
          xFormat: '%Y-%m-%d %H:%M:%S',
          columns: data,
        },
        point: {
          r: 4,
        },
        padding: {
          top: 20,
          left: 60,
          right: 20,
          bottom: 20
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: ticksConfX,
            label: {
              text: 'Date Time',
              position: 'outer-center'
            },
          },
          y: {
            // min: 0,
            padding: { top: 0, bottom: 0 },
            max: maxYval,
            min: 0
          }
        },

        grid: {
          y: {
            show: true
          },
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
  populateHeatmap() {
    try {
      let counter = 0;
      let data = [];
      let paramList = [];
      let stackList = [];
      let parameters: any = {};
      this.siteDetails.stackData.forEach(function (station, stationIndex) {

        stackList.push(station.stackName);
        let params = (Object.keys(station.dataAvailability));
        params.forEach(function (parameter) {
          if (parameters[parameter] || parameters[parameter] === 0) {
            data.push([stationIndex, parameters[parameter], station.dataAvailability[parameter]]);
          } else {
            paramList.push(parameter);
            parameters[parameter] = counter;
            counter++;
            data.push([stationIndex, parameters[parameter], station.dataAvailability[parameter]]);
          }
        });
      });
      setTimeout(() => {
        this.createCharts();
      }, 1000);
      const myChart = echarts.init(document.getElementById('dataAvailable'));

      const option = {
        tooltip: {
          position: 'top'
        },
        grid: {
          height: '50%',
          top: '10%'
        },
        xAxis: {
          type: 'category',
          data: stackList,
          splitArea: {
            show: true
          }
        },
        yAxis: {
          type: 'category',
          data: paramList,
          splitArea: {
            show: true
          }
        },
        visualMap: {
          min: 0,
          max: 100,
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '15%'
        },
        series: [{
          name: 'Punch Card',
          type: 'heatmap',
          data: data,
          label: {
            show: true
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      };
      myChart.setOption(option);
    } catch (e) {
      console.error(e);
    }
  }
  public pageLanguage:any;

  goBack() {
    
    this.pageType = 'all';
    this.router.navigate(['industry-rating', this.pageLanguage]);
  }
  action(row) {
    this.selectedIndustry = row;
    this.getSiteDetails(row);
  }
}
