import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from 'src/app/config/config';
import { AlertService } from '../../shared/alert.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
// import { Toast, ToasterConfig, ToasterService } from 'angular2-toaster';

@Component({
  selector: 'kl-news-update',
  templateUrl: './news-update.component.html',
  styleUrls: ['./news-update.component.scss']
})
export class NewsUpdateComponent implements OnInit {
  pageLabels: any;
  allPosts: any;
  private twitter: any;
  tweetFlag: Boolean = false;
  pageLanguage: string = 'en';
  public lanId;
  tableData = {
    headerContent: [],
    bodyContent: [],
  };
  dtOptions = {
    pagingType: 'full_numbers',
    language: {
      emptyTable: "No 5 star rated industries found"
    }
  };
  public galleryModalIndex = 0;
  public gallerCards = [
    {
      img: "url(./assets/images/gallery-1.jpg)",
      label: "JSPCB Star Rating Program Inaugration",
      imgurl: './assets/images/gallery-1.jpg'
    }, {
      img: "url(./assets/images/gallery-2.jpg)",
      label: "JSPCB Star Rating Program Inaugration",
      imgurl: './assets/images/gallery-2.jpg'
    }, {
      img: "url(./assets/images/gallery-3.jpg)",
      label: "JSPCB Star Rating Program Inaugration",
      imgurl: './assets/images/gallery-3.jpg'
    }, {
      img: "url(./assets/images/gallery-4.jpg)",
      label: "World Environment day",
      imgurl: './assets/images/gallery-4.jpg'
    }, {
      img: "url(./assets/images/gallery-5.jpg)",
      label: "JSPCB Star Rating Program Inaugration",
      imgurl: './assets/images/gallery-5.jpg'
    }, {
      img: "url(./assets/images/gallery-6.jpg)",
      label: "JSPCB Star Rating Program Inaugration",
      imgurl: './assets/images/gallery-6.jpg'
    }, {
      img: "url(./assets/images/gallery-7.jpg)",
      label: "World Environment day",
      imgurl: './assets/images/gallery-7.jpg'
    }, {
      img: "url(./assets/images/gallery-8.jpg)",
      label: "JSPCB Star Rating Program Inaugration",
      imgurl: './assets/images/gallery-8.jpg'
    }  ]

  public indexIncrementNews = false;
  public indexDecrementNews = false;
  public startIndexNews = 0;
  public showCardsNews = true;
  public indexIncrement = false;
  public indexDecrement = false;
  public startIndex = 0;
  public showCards = true;
  userLogged: Boolean = false;
  isTableDataLoaded: boolean = false;
  // public config1: ToasterConfig = new ToasterConfig({
  //   positionClass: 'toast-top-right'
  // });
  constructor(
    public _http: HttpLayerService,
    public _router: Router,
    private route: ActivatedRoute,
    public toaster: AlertService,
    ) {
    this.route.params.subscribe((params) => {
      this.lanId = params.id;
    });
  }

  ngOnInit() {
    this.getTranslations();
    this.getAllPosts();
    this.getIndustryList();
    this.getMedia();
  }

  public mediaGallery:any
  getMedia(){
    try {
      this._http.get(Config.JSON.GET_MEDIA).subscribe(response => {
        if (response.status) {
          this.mediaGallery=response.data.media
        } else {
          
        }
      })
    } catch (error) {
      console.error(error)
      this.toaster.open('error', 'Something went wrong, please try again.', 'Error');
    }
  }

  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['newsUpdateComponent']) {
            this.pageLabels = result['newsUpdateComponent'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  openGallery(i) {
    try {
      if(screen.width < 450) {
        return;
      }
      this.galleryModalIndex = i;
      document.getElementById('levelModalTrigger').click();
    } catch (e) {
      console.error(e);
    }
  }
  incrementIndexNews() {
    if ((this.startIndexNews + 3) < this.allPosts.length) {
      this.indexDecrementNews = false;
      this.indexIncrementNews = true;
      this.startIndexNews++;
      setTimeout(() => {
        this.indexIncrementNews = false;
        this.showCardsNews = true;
      }, 200);
    }
  }
  decrementIndexNews() {
    if (this.startIndexNews > 0) {
      this.indexDecrementNews = true;
      this.indexIncrementNews = false;
      this.startIndexNews--;
      setTimeout(() => {
        this.indexDecrementNews = false;
        this.showCardsNews = true;
      }, 250);
    }
  }
  incrementIndex() {
    if ((this.startIndex + 3) < this.gallerCards.length) {
      this.indexDecrement = false;
      this.indexIncrement = true;
      this.startIndex++;
      setTimeout(() => {
        this.indexIncrement = false;
        this.showCards = true;
      }, 200);
    }
  }
  decrementIndex() {
    if (this.startIndex > 0) {
      this.indexDecrement = true;
      this.indexIncrement = false;
      this.startIndex--;
      setTimeout(() => {
        this.indexDecrement = false;
        this.showCards = true;
      }, 250);
    }
  }
  incrementGalleryIndex() {
    if ((this.galleryModalIndex) < this.gallerCards.length) {
      this.galleryModalIndex++;
    }
  }
  decrementGalleryIndex() {
    if (this.galleryModalIndex > 0) {
      this.galleryModalIndex--;
    }
  }
  getIndustryList() {
    this.isTableDataLoaded = false;
    let input = {};
    this.tableData = {
      headerContent: [],
      bodyContent: [],
    };
    this.userLogged = ['admin', 'test'].indexOf(sessionStorage.getItem('userType')) > -1 ? true : false;
    input['fetchType'] = 'fetchAll';
    input['pageType'] = 'map_view';
    try {
      this._http
        .post(Config.API.GET_FILTERED_INDUSTRIES, input)
        .subscribe(result => {
          if (result.status && result['data']) {
            // this.tableData['bodyContent'] = result['data']['industries'];
            this.tableData['bodyContent'] = [];
            // this.tableData['bodyContent'].sort(this.dynamicSort('rating', 'num')).reverse();
            // this.tableData['bodyContent'].splice(5, this.tableData['bodyContent'].length);
            for (let i = 0; i < result['data']['industries'].length; i++) {
              const industry = result['data']['industries'][i];
              if (Number(industry['rating']) === 5) {
                this.tableData['bodyContent'].push(industry);
              }
            }
            this.tableData['headerContent'] = [
              {
                key: 'label',
                label: 'Industry',
              },
              {
                key: 'district',
                label: 'District / City',
              },
              {
                key: 'category',
                label: 'Category',
              },
              {
                key: 'rating',
                label: 'Rating',
              },
            ];
            this.isTableDataLoaded = true;
          } else {
            const toast = {
              type: 'error',
              title: 'Error',
              body: 'Something went wrong, please try again.',
              showCloseButton: true
            };
            // this.toasterService.pop(toast);
          }
        });
    } catch (error) {
      console.log(error);
      const toast = {
        type: 'error',
        title: 'Error',
        body: 'Something went wrong, please try again.',
        showCloseButton: true
      };
      // this.toasterService.pop(toast);
    }
  }

  getAllPosts() {
    let input = {};
    input['edit_type'] = 'fetch';
    try {
      this._http
        .post(Config.API.POSTS_API, input)
        .subscribe(result => {
          if (result['tableData']) {
            this.allPosts = this.sortThePost(result['tableData']['bodyContent']);
            for (let i = 0; i < this.allPosts.length; i++) {
              const post = this.allPosts[i];
              if (!post.imageUrl || post.imageUrl === '') {
                post.imageUrl = 'assets/images/section-header.png';
                post.imageName = 'JSPCB Post CoverImage';
              }
            }
          } else {
            const toast = {
              type: 'error',
              title: 'Error',
              body: 'Something went wrong, please try again.',
              showCloseButton: true
            };
            // this.toasterService.pop(toast);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  dynamicSort(property, checkType) {
    try {
      return function (a, b) {
        if (checkType === 'alpha') {
          return a[property].localeCompare(b[property]);
        } else {
          return a[property] - b[property];
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  gotoPostDetails(post) {
    if (!post['post_id']) return;

    // window.open(post,'_blank')
    this._router.navigate(['/public-news-updates-view', post['post_id']]);
    // this._router.navigate([]).then(result => {window.open( `/public-news-updates-view/${post['post_id']}`, '_blank')});

  }

  sortThePost(posts) {
    if (!posts || posts.length < 1) {
      return [];
    }
    posts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    return posts;
  }

  getTwitterURL(post) {
    let url = 'https://twitter.com/share?url=https%3A%2F%2Fjspcb.info/%23/public-news-updates-view/' + post.post_id + "/en";
    return url;
  }

  getFacebookURL(post) {
    let url = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fjspcb.info/%23/public-news-updates-view/' + post.post_id + "/en";
    return url;
  }

  // getURL(post) {
  //   let url =  'https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fjspcb.info%2F%23%2Fpublic-news-updates-view%2F'+ post.post_id +'%2Fen&layout=button&size=small&width=59&height=20&appId';
  //   console.log(url);
  //   return url;
  // }
}
