import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from '@angular/cdk/layout';
import { PagesModule } from './pages/pages.module';
import { ComponentsModule } from './components/components.module';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { NewsUpdateComponent } from './pages/news-update/news-update.component';
import { PagesRoutingModule } from './pages/pages-routing.module';
import { MultiSearchRatingComponent } from './pages/multi-search-rating/multi-search-rating.component';
import { MultiSearchCheckboxComponent } from './pages/multi-search-checkbox/multi-search-checkbox.component';
// // import { ToastrModule } from 'ng6-toastr-notifications';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthGuard } from './auth.guard';
import { AuthService } from './services/auth.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertService } from './shared/alert.service';
import { LoaderCommonComponent } from './shared/loader/loader-common.component';
import { LoaderService } from './shared/loader/loader-service.service';
import { HomeComponent } from './pages/home/home.component';
import { Config } from './config/config';
@NgModule({
  declarations: [
    AppComponent,
    LoaderCommonComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    PagesModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    ComponentsModule,
    // ToastrModule.forRoot(),
  ],
  entryComponents: [HomeComponent, NewsUpdateComponent, MultiSearchCheckboxComponent, MultiSearchRatingComponent],
  providers: [AlertService, LoaderService, Config],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
