import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpLayerService } from "src/app/services/http-layer.service";
import { Config } from "src/app/config/config";
import { AlertService } from '../../shared/alert.service';

@Component({
  selector: 'kl-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  pageLabels: any;
  pageLanguage;
  copyIndustryList: any;
  
  JharkhandMap = {
    zoom: 6.6,
    lat: 22.3,
    lng: 85.5799
  };
  geoJsonObject: {};
  industryList = [];
  public industryView = {
    industry: null,
    slide: false,
    filterResultLoad: true,
  };

  constructor(
    private router: Router,
    public _http: HttpLayerService,
    public toaster: AlertService
  ) { }

  ngOnInit() {
    this.getTranslations();
    let route = this.router.url.split('/');
    this.pageLanguage = route.pop();
  }

  getTo(page) {
    try {
      if (page === 'explore') {
        this.router.navigate(['/explore-ratings', this.pageLanguage]);
      } else if (page === 'about-program') {
        this.router.navigate(['/about-program', this.pageLanguage]);
      } else if (page === 'about-jspcb') {
        this.router.navigate(['/about-jspcb', this.pageLanguage]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['mainPageComponent']) {
            this.pageLabels = result['mainPageComponent'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  


  styleFunc(feature) {
    return ({
      clickable: false,
      fillColor: feature.getProperty('color'),
      strokeColor: '#779690',
      strokeWeight: 0.8
    });
  }
  onMouseOver(infoWindow, $event: MouseEvent) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }

}
