import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Config {
  // public static get BASE_POINT_API(): String { return 'http://192.168.2.37:12124/'; }
  public static get BASE_POINT_API(): String { return environment.API_HOST; }
  public static get BASE_POINT_JSON(): String { return 'assets/static-data/'; }

  public static SESSION_KEY = {
    TOKEN: 'token'
  };

  // API Base points
  public static API: any = {
    LOGIN: Config.BASE_POINT_API + 'webUserLogin',
    ADD_USER: Config.BASE_POINT_API + 'addUser',
    JSPCB_INDUSTRY_LIST: Config.BASE_POINT_API + 'fetchIndustries',
    DISPLAY_INDUSTRY: Config.BASE_POINT_API + 'displayIndustry',
    META_DATA: Config.BASE_POINT_API + 'metaData',
    REGISTER_MAIL: Config.BASE_POINT_API + 'registerMail',
    UPDATE_INDUSTRY: Config.BASE_POINT_API + 'updateIndustry',
    STATION_DATA: Config.BASE_POINT_API + 'stationData',
    STATION_WISE_DATA: Config.BASE_POINT_API + 'stationWiseData',
    LOGOUT: Config.BASE_POINT_API + 'logout',
    REPORT_FORMATION: Config.BASE_POINT_API + 'fetchIndustryData',
    // GET_RESET_PASSWORD: Config.BASE_POINT_API + 'webUserLogin',
    POSTS_API: Config.BASE_POINT_API + 'editPost',
    WORK_FLOW_API: Config.BASE_POINT_API + 'editWorkflow',
    GET_WORKFLOW_DROPDOWN_DATA: Config.BASE_POINT_API + 'workflowDropDown',
    GET_FILTERED_INDUSTRIES: Config.BASE_POINT_API + 'fetchIndustriesList',
    FETCH_SMS_HISTORY: Config.BASE_POINT_API + 'fetchSMSHistory',
    SMS_API: Config.BASE_POINT_API + 'sendMessage',
    GET_INDUSTRY_DATA_GRAPHS: Config.BASE_POINT_API + 'fetchFifteenMinuteAverageData',
    FILTER_DATA: Config.BASE_POINT_API + 'filterConditions',
    SELECTED_INDUSTRY: Config.BASE_POINT_API + 'selectedIndustry',
    MONTHLY_WISE_IND_DATA: Config.BASE_POINT_API + 'monthlyWiseIndustryData',
    INDUSTRY_REPORT: Config.BASE_POINT_API + 'industryReport',
    STATION_REPORT: Config.BASE_POINT_API + 'stationReport',
    FETCH_PARAMETER_LIST: Config.BASE_POINT_API + 'fetchParameter',
    TRANSLATE: 'https://translation.googleapis.com/language/translate/v2',
    GET_STAR_GRAPH_DATA: Config.BASE_POINT_API + 'fetchTrendGraphData',
    SUBMIT_FEEDBACK: Config.BASE_POINT_API + 'feedback',
    GET_WORKFLOW: Config.BASE_POINT_API + 'getWorkflow',
    DATA_AVAILABILITY_TABLE: Config.BASE_POINT_API + 'starratingReportCard',

    jspcbIndustryData: Config.BASE_POINT_API + 'jspcbIndustryData',
    jspcbIndustryDetails: Config.BASE_POINT_API + 'jspcbIndustryDetails',
    cityWiseStarRating: Config.BASE_POINT_API + 'cityWiseStarRating',
    //FILTER_DATA: 'http://192.168.0.210:12124/filterConditions',
    //  jspcbIndustryData: 'http://192.168.0.210:12124/starratings/jspcbIndustryData',
    //    jspcbIndustryDetails: 'http://192.168.0.210:12124/starratings/jspcbIndustryDetails',
    //     cityWiseStarRating:'http://192.168.0.210:12124/starratings/cityWiseStarRating'
  };

  // Json Base Points
  public static JSON: any = {
    STP_DASHBOARD: './assets/jsons/stpSideBar.json',
    GET_SITES: './assets/jsons/sites.json',
    GET_SMS_HISTORY: './assets/jsons/smsHistory.json',
    GET_WORKFLOW_HISTORY: './assets/jsons/workFlowhistory.json',
    GET_ALL_POSTS: './assets/jsons/postsView.json',
    GET_INDUSTRY_DATA_GRAPHS: './assets/jsons/dashBoardgraphs.json',
    FILTER_DATA: './assets/jsons/filtersData.json',
    GET_FILTERED_INDUSTRIES: './assets/jsons/filterResults.json',
    GET_SITE_PARAMETERS: './assets/jsons/paramterList.json',
    GET_SMS_DETAILS: './assets/jsons/retrieveSMS.json',
    INTERNATIONALIZATION: './assets/jsons/internationalization.json',
    GET_MEDIA: './assets/jsons/mediaLimeLight.json',
    GET_STAR_GRAPH_DATA: './assets/jsons/starGraph.json',
    GET_JHARKHAND_GEO: './assets/jsons/geoJsonJk.json',
    postLoginData: './assets/jsons/loginResponse.json',
    availabilityData: './assets/jsons/dataAvailabilityTable.json',
    allSiteData: './assets/jsons/allSiteData.json',
    siteDetails: './assets/jsons/siteDetails.json',
    jspcbIndustryData: './assets/jsons/jspcbIndustryData.json',
    allData: './assets/jsons/searchFilterNew.json',
    reportFormat:'./assets/jsons/report-format.json',
    JSPCB_INDUSTRY_LIST: './assets/jsons/fetchIndustries.json',
    TABS_LIST : './assets/jsons/getTabsList.json',
    FAQ_DATA : './assets/jsons/faqData.json',
    GET_ABOUTPAGE_CONTENT :'./assets/jsons/getAboutPageInfo.json',
    GET_METHODOLOGY_CONTENT :'./assets/jsons/getMethodologyInfo.json',
    GET_INDUSTRY_LIST : './assets/jsons/industry_slides.json'
    
  };

  // Keys we need to use across application. For logics and etc
  public static CONSTANTS: any = {
    USER_NAME: 'Admin',
    USER_TYPE: 'admin',
    USER_TYPES: ['admin', 'industry', 'regulator'],
    GRAPH_COLORS: ['#0058A1', '#0072C3', '#1193E8', '#30B0FF'], // '#B3E6FF', '#6CCAFF',
    LEAFLETTILES: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    // LEAFLETTILES: 'https://bhuvan-vec1.nrsc.gov.in/bhuvan/gwc/service/wms/'
    // LEAFLETTILES: 'https://bhuvan-app1.nrsc.gov.in/state/JH'
  };

  // Alert messages to show in Application
  public static ALERT_MESSAGES: any = {

  };
  // public language = 'english';
  // constructor(public route: ActivatedRoute) {
  //   route.params.subscribe((params) => {
  //     this.language = params.id || 'language';
  //     switch (this.language) {
  //       case 'hindi':
  //         Config.JSON.INTERNATIONALIZATION = './assets/jsons/internationalization_hindi.json';
  //         break;
  //       case 'english':
  //         Config.JSON.INTERNATIONALIZATION = './assets/jsons/internationalization.json';
  //         break;
  //       case 'marathi':
  //         Config.JSON.INTERNATIONALIZATION = './assets/jsons/internationalization_marathi.json';
  //         break;

  //       default:
  //         Config.JSON.INTERNATIONALIZATION = './assets/jsons/internationalization.json';
  //         break;
  //     }
  //   });
  // }
}
