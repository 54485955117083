import { Component, OnInit } from '@angular/core';
import * as echarts from 'echarts';
import { Config } from 'src/app/config/config';
import { AlertService } from 'src/app/alert-service.service';
import { HttpLayerService } from 'src/app/services/http-layer.service';

@Component({
  selector: 'kl-dashboard-new',
  templateUrl: './dashboard-new.component.html',
  styleUrls: ['./dashboard-new.component.scss']
})
export class DashboardNewComponent implements OnInit {
  public monthYear2: any ={};
  public indArray: any = [];
  public year: any;
  public industryList: any = [];
  public reportDetails: any = {};
  public graphData:any={};
  public starAxis:boolean=true;
  public averageAxis:boolean=false;
  public dataAxis:boolean=false;
  public legend:any='Star Rating';
  constructor(public _http: HttpLayerService,
    public toaster: AlertService) { }

  ngOnInit() {
    this.getMonthAndYear()
    this.getIndustryList()

  }
  getIndustryList() {
    try {
      this._http.get(Config.API.DISPLAY_INDUSTRY).subscribe((result) => {
        if (result.status==='success' && result.data) {
          result.data.forEach(element => {
            if (element) {
              this.indArray.push(element.industry_name)
            }
          });
          this.reportDetails={
            industryName:this.indArray[0],
            selectedYear:new Date().getFullYear()
          }
          this.fetch()
        } else {
          this.toaster.open('error', result.message || 'Something went wrong, please try again.', result.status ||'Error');
        }
      });
    } catch (error) {
      console.log(error);
      this.toaster.open('error', 'Something went wrong, please try again.', 'Failed');
    }
  }
  getMonthAndYear() {
    const months = [
      {
        key: 1,
        value: "January"
      },
      {
        key: 2,
        value: "February"
      },
      {
        key: 3,
        value: "March"
      },
      {
        key: 4,
        value: "April"
      },
      {
        key: 5,
        value: "May"
      },
      {
        key: 6,
        value: "June"
      },
      {
        key: 7,
        value: "July"
      },
      {
        key: 8,
        value: "August"
      },
      {
        key: 9,
        value: "September"
      },
      {
        key: 10,
        value: "October"
      },
      {
        key: 11,
        value: "November"
      },
      {
        key: 12,
        value: "December"
      }
    ]
    let i;

    let baseYear = 2022
    let currentYear = new Date().getFullYear()
    let currentMonth = new Date().getMonth()
    for (i = baseYear; i <= currentYear; i++) {
      this.monthYear2[i] = [currentMonth]
      if (i === currentYear) {
        this.monthYear2[i] = []
        for (let j = 0; j < currentMonth; j++) {
          this.monthYear2[i].push(months[j])
        }
      } else {
        this.monthYear2[i] = months
      }
    }
    this.year = Object.keys(this.monthYear2)
  }
  // graph() {
  //   const myChart = echarts.init(document.getElementById('dataAvailablity'));
  //   var values=this.graphData.starRating.value;

  //   const colorCode={
  //     0:"#000",
  //     1:"#c00000",
  //     2:"#f4b081",
  //     3:"#ffd865",
  //     4:"#a8d08c",
  //     5:"#538135"
  //   }
  //   var newValues=[];
  //   values.forEach(element => {
  //     const obj={
  //       value: element,
  //       itemStyle: {color: colorCode[element] || "#fff"},
  //   }
  //   newValues.push(obj)
  //   })
  //    const self=this;
  //   const option = {
  //     title: {
  //       // text: this.reportDetails.industryName + '-' + this.reportDetails.selectedYear +'-' + this.legend,
  //       text: this.reportDetails.industryName,
  //       left: 'center'
  //     },
  //     tooltip: {
  //       trigger: 'axis',
  //     },
  //     grid: {
  //       left: '10%',
  //       bottom: '15%',
  //       top:'20%'
  //     },
  //     toolbox: {
  //       feature: {
  //         magicType: {
  //           show: false, title: {
  //             line: 'Line Graph',
  //             bar: 'Bar Graph'
  //           }, type: ['line', 'bar']
  //         },
  //         restore: { show: false, title: 'Restore' },
  //         saveAsImage: { show: true, title: 'Save the Image' }
  //       }
  //     },
  //     legend: {
  //       data: ['Star Rating', 'PM Average', 'Data Availability'],
  //       selectedMode:'single',
  //       top:'bottom'
  //     },
  //     xAxis: [
  //       {
  //         type: 'category',
  //         name:'Months',
  //         nameLocation:'middle',
  //         nameGap:'30',
  //         data: this.graphData.starRating.month,
  //         axisPointer: {
  //           type: 'shadow'
  //         }
  //       }
  //     ],
  //     yAxis: [
  //       {
  //         type: 'value',
  //         name: 'Star Rating',
  //         nameLocation: "middle",
  //         nameGap: 30,
  //         position:'left',
  //         min: 0,
  //         max: 5,
  //         axisLabel: {
  //           formatter: '{value}'
  //         },
  //         show:this.starAxis
  //       },
  //       {
  //         type: 'value',
  //         name: 'PM Average',
  //         nameLocation: "middle",
  //         nameGap: 30,
  //         position:'left',
  //         axisLabel: {
  //           formatter: '{value}'
  //         },
  //         show:this.averageAxis
  //       },
  //       {
  //         type: 'value',
  //         name: 'Data Availability',
  //         nameLocation: "middle",
  //         nameGap: 30,
  //         position:'left',
  //         min: 0,
  //         max: 100,
  //         axisLabel: {
  //           formatter: '{value}'
  //         },
  //         show:this.dataAxis
  //       }
  //     ],
  //     series: [
  //       {
  //         name: 'Star Rating',
  //         type: 'bar',
  //         barWidth: '20%',
  //         label: {
  //           position: 'top',
  //           show: true
  //         },
  //         data: newValues
  //       },
  //       {
  //         name: 'PM Average',
  //         type: 'bar',
  //         barWidth: '20%',
  //         yAxisIndex: 1,
  //         label: {
  //           position: 'top',
  //           show: true
  //         },
  //         tooltip: {
  //           valueFormatter: function (value) {
  //             return value as number + '';
  //           }
  //         },
  //         data: this.graphData.average.value
  //       },
  //       {
  //         name: 'Data Availability',
  //         type: 'bar',
  //         barWidth: '20%',
  //         yAxisIndex: 2,
  //         label: {
  //           position: 'top',
  //           show: true
  //         },
  //         tooltip: {
  //           valueFormatter: function (value) {
  //             return value as number + '';
  //           }
  //         },
  //         data: this.graphData.dataAvailability.value
  //       }
  //     ]
  //   }
  //   myChart.setOption(option);
  //   myChart.on('legendselectchanged', function (params) {
  //     selectGraph(params);
  //     unselectGrap(params);
  //   });
  //   function selectGraph(params) {
  //     myChart.dispatchAction({
  //       type: 'legendSelect',
  //       name: params.name,
  //     })
  //     self.legend=params.name
  //     if(self.legend==='Star Rating'){
  //       self.starAxis=true;
  //       self.averageAxis=false;
  //       self.dataAxis=false;
  //       self.legend==='Star Rating'
  //       self.graph()
  //     }
  //     else if(self.legend==='PM Average'){
  //       self.starAxis=false;
  //       self.averageAxis=true;
  //       self.dataAxis=false;
  //       self.legend==='PM Average'
  //       self.graph()
  //     }
  //     else{
  //       self.starAxis=false;
  //       self.averageAxis=false;
  //       self.dataAxis=true;
  //       self.legend==='Data Availability'
  //       self.graph()
  //     }
  //   }
  //   function unselectGrap(params) {
  //     for (const legend in params.selected) {
  //       if (legend !== params.name) {
  //         myChart.dispatchAction({
  //           type: 'legendUnSelect',
  //           name: legend,
  //         })
  //       }
  //     }
  //   }
  // }
  graph() {
    const myChart = echarts.init(document.getElementById('dataAvailablity'));
    var values=this.graphData.starRating.value;

    const colorCode={
      0:"#000",
      1:"#c00000",
      2:"#f4b081",
      3:"#ffd865",
      4:"#a8d08c",
      5:"#538135"
    };
    var newValues=[];
    values.forEach(element => {
      const obj={
        value: element,
        itemStyle: {color: colorCode[element] || "#fff"},
    };
    newValues.push(obj);
    });
    const option = {
      title: {
        text: this.reportDetails.industryName,
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        left: '10%',
        bottom: '15%',
        top:'20%'
      },
      toolbox: {
        feature: {
          magicType: {
            show: false, title: {
              line: 'Line Graph',
              bar: 'Bar Graph'
            }, type: ['line', 'bar']
          },
          restore: { show: false, title: 'Restore' },
          saveAsImage: { show: true, title: 'Save the Image' }
        }
      },
      legend: {
        data: ['Star Rating', 'PM Average', 'Data Availability'],
        selectedMode:'single',
        top:'bottom'
      },
      xAxis: [
        {
          type: 'category',
          name:'Months',
          nameLocation:'middle',
          nameGap:'30',
          data: ['AUG','SEP'],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          position:'left',
          min: 0,
          max: 5,
          axisLabel: {
            formatter: '{value}'
          },
          show:true
        },
        // {
        //   type: 'value',
        //   position:'left',
        //   axisLabel: {
        //     formatter: '{value}'
        //   },
        //   show:true
        // },
        // {
        //   type: 'value',
        //   position:'left',
        //   // min: 0,
        //   // max: 100,
        //   axisLabel: {
        //     formatter: '{value}'
        //   },
        //   show:true
        // }
      ],
      series: [
        {
          name: 'Star Rating',
          type: 'line',
          barWidth: '20%',
          label: {
            position: 'top',
            show: true
          },
          data: newValues
        },
        // {
        //   name: 'PM Average',
        //   type: 'line',
        //   barWidth: '20%',
        //   yAxisIndex: 1,
        //   label: {
        //     position: 'top',
        //     show: true
        //   },
        //   tooltip: {
        //     valueFormatter: function (value) {
        //       // return value as number + '';
        //     }
        //   },
        //   data: this.graphData.average.value
        // },
        // {
        //   name: 'Data Availability',
        //   type: 'line',
        //   barWidth: '20%',
        //   yAxisIndex: 2,
        //   label: {
        //     position: 'top',
        //     show: true
        //   },
        //   tooltip: {
        //     valueFormatter: function (value) {
        //       // return value as number + '';
        //     }
        //   },
        //   data: this.graphData.dataAvailability.value
        // }
      ]
    };
    myChart.setOption(option);
  }
  fetch(){
    try {
      const payload=this.reportDetails
      this._http.post(Config.API.MONTHLY_WISE_IND_DATA,payload).subscribe(response =>{
        if(response.status){
          this.graphData=response.data
          this.graph()
        }
        else{
          this.toaster.open('error', response.message || 'Failed To Load Data', response.status || 'Failed')
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
}
