import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpLayerService } from './services/http-layer.service';
import { AlertService } from './shared/alert.service';
import { Config } from 'src/app/config/config';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private _http: HttpLayerService, public toaster: AlertService) { }
  canActivate() {
    const userType = Config.CONSTANTS.USER_TYPES;
    const currentUser = sessionStorage.getItem('userType');
    let pageLanguage: any = sessionStorage.getItem('language');
    let userDetails:any = sessionStorage.getItem('userDetails');
    if (!pageLanguage) {
      pageLanguage = 'en';
    }
    try {
      if ( userDetails ) {
        return true;
      } else {
        this._router.navigate(['/home']);
        return false;
      }
    } catch (error) {
      this.toaster.open('warning', '', 'Session Expired! Please Login again');
      this._router.navigate(['/home']);
      console.log(error);
      return false;
    }

    return false;
  }

}
