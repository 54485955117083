import { Component, OnInit } from '@angular/core';
import { HttpLayerService } from "src/app/services/http-layer.service";
import { Config } from "src/app/config/config";
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AlertService } from '../../shared/alert.service';

@Component({
  selector: 'kl-about-star-pgm',
  templateUrl: './about-star-pgm.component.html',
  styleUrls: ['./about-star-pgm.component.scss'],
})
export class AboutStarPgmComponent implements OnInit {
  askPanels = [];
  coverPage: any;
  feedbackFields: any;
  yStarRating: any;
  jharkhand: any;
  feeback = {
    name: '',
    email: '',
    mobile: '',
    comment: ''
  };
  pageLanguage: string;
  constructor(
    public _http: HttpLayerService,
    public toaster: AlertService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getTranslations();
    let route = this.router.url.split('/');
    this.pageLanguage = route.pop();
  }
  getTranslations() {
    try {
      this._http
        .get(Config.JSON.INTERNATIONALIZATION)
        .subscribe(result => {
          if (result['aboutStarProgramComponent']) {
            this.askPanels = result['aboutStarProgramComponent']['askPanels'];
            this.coverPage = result['aboutStarProgramComponent']['coverPage'];
            this.feedbackFields = result['aboutStarProgramComponent']['feedback'];
            this.yStarRating = result['aboutStarProgramComponent']['yStarRating'];
            this.jharkhand = result['aboutStarProgramComponent']['jharkhand'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  isFeedbackValid() {
    let valid: boolean = true;
    if (this.feeback.name.trim() === '' || this.feeback.email.trim() === '' || this.feeback.comment.trim() === '') {
      valid = false;
    }
    return !valid;
  }
  submitFeedback() {
    let input = this.feeback;
    JSON.stringify(input);
    try {
      this._http
        .post(Config.API.SUBMIT_FEEDBACK, input)
        .subscribe(result => {
          this.toaster.open('success', '', 'Feedback submitted');
        });
    } catch (error) {
      console.log(error);
      this.toaster.open('error', '', 'Something went wrong, please try again.');
    }
  }
  navigate(route) {
    try {
      this.router.navigate([route, this.pageLanguage.toLowerCase()]);
    } catch (error) {
      console.log(error);
    }
  }

  redirectPage(id){
    if(id === 'contactUs') {
      this.navigate('/contact')
    } else if(id === 'exploreJarkhand') {
      this.navigate('/whyJarkhand')
    } else if(id === 'starRatingWhy') {
      this.navigate('/whyStarRaring')
    }
  }
}
